import React, { useState, useEffect, useRef } from "react";
import { deleteExistingProject, updateModal, closeModal } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import "../style/Modal.css";

function DeleteProjectModal({ isOpen }) {
    const [confirmValue, setConfirmValue] = useState("");
    const [isDeletingInProgress, setIsDeletingInProgress] = useState(false);
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const { modal } = useSelector((state) => state.project);

    const title = `Delete Project: ${modal.data?.project_name || ''}`;
    const confirmText = "delete";

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target) &&
                !isDeletingInProgress
            ) {
                dispatch(updateModal({ name: '', data: null }));
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch, isDeletingInProgress]);

    useEffect(() => {
        setConfirmValue("");
    }, [isOpen]);

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
        if (confirmValue !== confirmText) {
            alert(`Please type "${confirmText}" to confirm.`);
            return;
        }
        try {
            setIsDeletingInProgress(true);
            const deletedProjectId = modal.data.project_id;
            await dispatch(deleteExistingProject({ projectId: deletedProjectId })).unwrap();
            dispatch(closeModal());
        } catch (error) {
            console.error("Error deleting project:", error);
        } finally {
            setIsDeletingInProgress(false);
        }
    };

    return (
        <div 
            className={`delete-project-modal-overlay ${isDeletingInProgress ? 'deleting' : ''}`} 
            onClick={(e) => {
                if (!isDeletingInProgress) {
                    e.stopPropagation();
                }
            }}
        >
            <div className="delete-project-modal-content" ref={modalRef}>
                <h2 className="delete-project-modal-title">{title}</h2>
                {isDeletingInProgress ? (
                    <div className="delete-project-loading">
                        <p>Deleting project. Please wait...</p>
                        <div className="delete-project-loading-spinner"></div>
                    </div>
                ) : (
                    <>
                        <p>Are you sure you want to delete this project? This action cannot be undone.</p>
                        <form onSubmit={handleDeleteSubmit}>
                            <input
                                type="text"
                                value={confirmValue}
                                onChange={(e) => setConfirmValue(e.target.value)}
                                placeholder={`Type "${confirmText}" to confirm`}
                                className="delete-project-input"
                            />
                            <div className="delete-project-modal-buttons">
                                <button type="button" onClick={() => dispatch(closeModal())} className="delete-project-cancel-button">
                                    Cancel
                                </button>
                                <button type="submit" className="delete-project-submit-button">Delete</button>
                            </div>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
}

export default DeleteProjectModal;
