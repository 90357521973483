import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateModal, submitOrder } from '../store/project.js';
import { setPopupMessage } from '../store/user.js';
import '../style/OrderDNA.css';

function OrderDNA() {
  const dispatch = useDispatch();
  const modalData = useSelector((state) => state.project.modal.data);
  const currentProjectId = useSelector((state) => state.project.currentProjectId);
  const [sequenceVerify, setSequenceVerify] = useState(true);

  const handleClose = () => {
    dispatch(updateModal({ name: null, data: null }));
  };

  const handleExecute = () => {
    dispatch(submitOrder({
      projectId: currentProjectId,
      orderData: modalData,
      sequenceVerify
    }))
    .unwrap()
    .then(() => {
      dispatch(setPopupMessage("Request submitted. The LabKick team will be in contact with you via email shortly"));
      handleClose();
    })
    .catch((error) => {
      dispatch(setPopupMessage("Failed to submit order request. Please try again."));
      handleClose();
    });
  };

  const sequenceVerifyCost = sequenceVerify ? 19 * modalData.total_constructs : 0;
  const totalPrice = modalData.total_price + sequenceVerifyCost;

  return (
    <>
      <div className="order-dna-overlay" onClick={handleClose} />
      <div className="order-dna-modal">
        <div className="order-dna-header">
          <h2>Order Form</h2>
          <button className="order-dna-close" onClick={handleClose}>×</button>
        </div>
        
        <div className="order-dna-content">
          <div className="order-dna-form">
            <div className="order-dna-process">
              <div className="order-dna-process-title">How it works</div>
              <div className="order-dna-process-steps">
                <div className="order-dna-process-step">
                  <span className="order-dna-step-number">1</span>
                  <span className="order-dna-step-text">Submit Order</span>
                  <span className="order-dna-step-description">Customer submits order through LabKick platform</span>
                </div>
                <span className="order-dna-step-arrow">→</span>
                <div className="order-dna-process-step">
                  <span className="order-dna-step-number">2</span>
                  <span className="order-dna-step-text">Send Input DNA</span>
                  <span className="order-dna-step-description">Customer sends any DNA not being synthesized to LabKick</span>
                </div>
                <span className="order-dna-step-arrow">→</span>
                <div className="order-dna-process-step">
                  <span className="order-dna-step-number">3</span>
                  <span className="order-dna-step-text">Lab Work & Video</span>
                  <span className="order-dna-step-description">LabKick performs experiments and shares videos and data</span>
                </div>
                <span className="order-dna-step-arrow">→</span>
                <div className="order-dna-process-step">
                  <span className="order-dna-step-number">4</span>
                  <span className="order-dna-step-text">Sequence Verify</span>
                  <span className="order-dna-step-description">LabKick sequence verifies all output DNA</span>
                </div>
                <span className="order-dna-step-arrow">→</span>
                <div className="order-dna-process-step">
                  <span className="order-dna-step-number">5</span>
                  <span className="order-dna-step-text">Return DNA</span>
                  <span className="order-dna-step-description">LabKick returns DNA to customer</span>
                </div>
              </div>
            </div>

            <div className="order-dna-summary">
              <div className="order-dna-summary-item">
                <span>Total Constructs</span>
                <span className="order-dna-summary-value-light">{modalData?.total_constructs || 0}</span>
              </div>

              <div className="order-dna-summary-item">
                <span>Synthesis Cost</span>
                <span className="order-dna-summary-value-light">${modalData?.synthesis_cost || 0}</span>
              </div>

              <div className="order-dna-summary-item">
                <span>Assembly Cost</span>
                <span className="order-dna-summary-value-light">
                  ${(modalData?.labor_cost || 0) + (modalData?.protocol_cost || 0)}
                </span>
              </div>

              <div className="order-dna-summary-item">
                <label className="order-dna-checkbox-label">
                  <div className="order-dna-verify-left">
                    <input 
                      type="checkbox"
                      checked={sequenceVerify}
                      onChange={(e) => setSequenceVerify(e.target.checked)}
                    />
                    <span>Sequence Verify Outputs</span>
                  </div>
                  <span className="order-dna-summary-value-light">
                    ${sequenceVerifyCost}
                  </span>
                </label>
              </div>

              <div className="order-dna-total">
                <div className="order-dna-total-row">
                  <span>Total Price (shipping not included)</span>
                  <span className="order-dna-summary-value-bold">${totalPrice}</span>
                </div>
              </div>

              <div className="order-dna-summary-item">
                <span>Estimated Lead Time</span>
                <span className="order-dna-summary-value-light">{modalData?.lead_time}</span>
              </div>
            </div>

            <div className="order-dna-beta-message">
              {modalData?.beta_message}
            </div>
          </div>
        </div>

        <div className="order-dna-footer">
          <button 
            className="order-dna-button order-dna-cancel" 
            onClick={handleClose}
          >
            Cancel
          </button>
          <button 
            className="order-dna-button order-dna-submit" 
            onClick={handleExecute}
          >
            Submit Request
          </button>
        </div>
      </div>
    </>
  );
}

export default OrderDNA;


