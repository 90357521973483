import React, { useState, useEffect } from "react";
import "../style/PaymentModal.css";
import { createCheckoutSession, resendVerificationEmail } from "../api.js";
import { useSelector, useDispatch } from "react-redux";
import { updateModal } from "../store/project.js";
import { useAuth0 } from '@auth0/auth0-react';

const PaymentModal = () => {
    const dispatch = useDispatch();
    const token = useSelector(state => state.user.access_token);
    const userId = useSelector(state => state.user.user_id);
    const [message, setMessage] = useState("");
    const [credits, setCredits] = useState("10");
    const currentCredits = useSelector(state => state.user.usageCredits) || 0;
    const [isEmailVerificationPopupOpen, setIsEmailVerificationPopupOpen] = useState(false);
    const { getAccessTokenSilently, user: userData } = useAuth0();

    useEffect(() => {
        checkUrlParams();
    }, []);

    const checkUrlParams = () => {
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.get("success") === "true") {
            dispatch(setPopupMessage("Payment succeeded! You will receive an email confirmation."));
            removeQueryParams();
            dispatch(updateModal({ name: "", data: null }));
        } else if (searchParams.get("canceled") === "true") {
            dispatch(setPopupMessage("Payment failed or was canceled. Please try again."));
            removeQueryParams();
            dispatch(updateModal({ name: "", data: null }));
        }
    };

    const removeQueryParams = () => {
        const url = new URL(window.location);
        url.searchParams.delete("success");
        url.searchParams.delete("canceled");
        window.history.replaceState({}, document.title, url.pathname);
    };

    const handleInputChange = (e) => {
        setCredits(e.target.value);
    };

    const handleCheckout = async (e) => {
        e.preventDefault();
        const creditValue = parseInt(credits);

        if (isNaN(creditValue) || !Number.isInteger(creditValue)) {
            setMessage("Please enter a whole number between 1 and 100 credits.");
            return;
        }

        if (creditValue < 1) {
            setMessage("You must purchase at least 1 credit.");
            return;
        }

        if (creditValue > 100) {
            setMessage("For purchases over 100 credits, please contact sales@labkick.ai for a bigger discount.");
            return;
        }

        try {
            const token = await getAccessTokenSilently();
            const response = await fetch('https://login.labkick.ai/userinfo', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            const userInfo = await response.json();

            if (!userInfo.email_verified) {
                setIsEmailVerificationPopupOpen(true);
                return;
            }

            const session = await createCheckoutSession(token, userId, creditValue);
            window.location.href = session.url;
        } catch (error) {
            console.error("Error during checkout:", error);
            setMessage("An error occurred. Please try again.");
        }
    };

    const handleResendVerification = async () => {
        try {
            const token = await getAccessTokenSilently();
            await resendVerificationEmail(userData, token);
            setIsEmailVerificationPopupOpen(false);
            setMessage("Verification email sent! Please check your inbox.");
        } catch (error) {
            console.error('Error sending verification email:', error);
            setMessage("Failed to send verification email. Please try again.");
        }
    };

    const handleClose = () => {
        removeQueryParams();
        setMessage(""); // Reset message when closing the modal
        dispatch(updateModal({ name: "", data: null }));
    };

    const handleGroupManagement = () => {
        dispatch(updateModal({ name: "group_management", data: null }));
    };

    const totalPrice = Number.isInteger(Number(credits)) && Number(credits) >= 0 ? Number(credits) * 10 : 0;

    return (
        <div className="checkout-modal-overlay" onClick={handleClose}>
            <div className="checkout-modal-content" onClick={(e) => e.stopPropagation()}>
                <h2 className="checkout-modal-title">Credits</h2>
                <hr className="checkout-modal-divider" />
                <div className="checkout-current-credits-container">
                    <p className="checkout-current-credits">{currentCredits}</p>
                    <span className="checkout-current-credits-label">Current credits</span>
                </div>
                <hr className="checkout-modal-divider" />
                <p className="checkout-subtitle">Purchase credits for DNA assembly plans</p>
                <div className="checkout-input-container">
                    <div className="checkout-input-wrapper">
                        <input
                            type="text"
                            value={credits}
                            onChange={handleInputChange}
                            placeholder="Enter number of credits"
                            className="checkout-credit-input"
                        />
                    </div>
                    <button onClick={handleCheckout} className="checkout-buy-button">
                        Buy
                    </button>
                </div>
                <div className="checkout-info-row">
                    <p className="checkout-total-price">Total: ${totalPrice}</p>
                    <p className="checkout-contact-sales">
                        Need more? <a href="mailto:sales@labkick.ai">Contact sales.</a>
                    </p>
                </div>
                <div className="checkout-group-management">
                    <button onClick={handleGroupManagement} className="checkout-group-button">
                        Manage Groups
                    </button>
                </div>
                {message && <p className="checkout-message">{message}</p>}
                
                {isEmailVerificationPopupOpen && (
                    <div className="artifact-confirmation-popup-overlay">
                        <div className="artifact-confirmation-popup-dialog">
                            <h3>Email Not Verified</h3>
                            <p>Please verify your email before purchasing credits. Check your junk mailbox if you don't see a verification email.</p>
                            <div className="artifact-confirmation-popup-buttons">
                                <button
                                    onClick={handleResendVerification}
                                    className="artifact-confirm-button"
                                >
                                    Resend Verification Email
                                </button>
                                <button
                                    onClick={() => setIsEmailVerificationPopupOpen(false)}
                                    className="artifact-cancel-button"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentModal;
