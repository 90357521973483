import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import { seqparse } from "seqparse";
import {
  fetchChatMessages,
  fetchProjectList,
  renameFile,
  deleteFileFromProject,
  createProject,
  renameProject,
  deleteProjectPermanent,
  sendMessage,
  uploadFiles,
  fetchArtifact,
  fetchDesignObject,
  addFileToProject as addFileToProjectAPI,
  generateInstructions,
  navigateDesignAPI,
  submitArtifactInputsAPI,
  editProtocol,
  fetchOrderData,
  submitOrderRequest,
} from "../api.js";
import { updateUser, fetchUserCredits } from "./user.js";

export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async (userId, { getState }) => {
    console.log("fetchProjects userId", userId);
    const token = getState().user.access_token;
    console.log("fetchProjects token", token);
    const response = await fetchProjectList(userId, token);
    return response;
  }
);

export const fetchChat = createAsyncThunk(
  "projects/fetchChat",
  async (projectId, { getState, dispatch }) => {
    const token = getState().user.access_token;
    try {
      const response = await fetchChatMessages(projectId, token);
      return { projectId, chat: response };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDesign = createAsyncThunk(
  "projects/fetchDesign",
  async (projectId, { getState }) => {
    const token = getState().user.access_token;
    try {
      const response = await fetchDesignObject(projectId, token);
      return { projectId, design: response };
    } catch (error) {
      throw error;
    }
  }
);


export const createNewProject = createAsyncThunk(
  "projects/createNewProject",
  async (projectName, { getState, dispatch }) => {
    const token = getState().user.access_token;  // Get the access token from the user slice of the Redux state
    const user_id = getState().user.user_id;
    const response = await createProject(projectName, user_id, token);  // Pass the token to the API function
    dispatch(setCurrentProjectId(response.project_id));  // Dispatch any needed actions
    return response;
  }
);

export const renameExistingProject = createAsyncThunk(
  "projects/renameExistingProject",
  async ({ projectId, newName }, { getState }) => {
    const token = getState().user.access_token;  // Get the access token from the user slice of the Redux state
    const user_id = getState().user.user_id;
    await renameProject(projectId, newName, user_id, token);
    return { projectId, newName };
  }
);

export const deleteExistingProject = createAsyncThunk(
  "projects/deleteExistingProject",
  async ({ projectId }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const user_id = getState().user.user_id;
    const response = await deleteProjectPermanent(projectId, user_id, token);
    console.log("deleteExistingProject response", response);
    dispatch(updateArtifact(null)); // Add this line to close the artifact panel
    return { projectId, updatedProjects: response };
  }
);

export const deleteProjectFile = createAsyncThunk(
  "projects/deleteProjectFile",
  async ({ projectId, fileId }, { getState, dispatch }) => {
    const token = getState().user.access_token;  // Get the access token from the user slice of the Redux state
    console.log("deleteProjectFile projectId:", projectId, "fileId:", fileId);
    await deleteFileFromProject(projectId, fileId, token);
    dispatch(fetchDesign(projectId));
    return { projectId, fileId };
  }
);

export const renameFileGlobal = createAsyncThunk(
  "projects/renameFileGlobal",
  async ({ fileId, newName, oldName, userId }, { getState }) => {
    const token = getState().user.access_token;  // Get the access token from the user slice of the Redux state
    const response = await renameFile(fileId, newName, oldName, userId, token);
    return { fileId, newName, response };
  }
);

export const sendChatMessage = createAsyncThunk(
  "projects/sendChatMessage",
  async ({ message, chatId, projectId, currentProject }, { dispatch, getState }) => {
    const token = getState().user.access_token;

    dispatch(setIsChatLoading(true));
    dispatch(addUserMessage({ projectId, message }));
    dispatch(clearSendMessageError()); // Clear any previous send message error

    try {
      const response = await sendMessage(message, chatId, projectId, currentProject, token);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let finalData = null;
      let buffer = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        let boundary = buffer.indexOf('\n\n');

        while (boundary !== -1) {
          const line = buffer.slice(0, boundary);
          buffer = buffer.slice(boundary + 2);

          if (line.startsWith('data: ')) {
            try {
              const eventData = JSON.parse(line.slice(6));
              if (eventData.status === 'update') {
                console.log('Streamed update:', eventData.message);
                dispatch(setCurrentStreamedUpdate(eventData.message));
              } else if (eventData.status === 'complete') {
                console.log('Final data received');
                finalData = eventData.data;
              } else if (eventData.status === 'error') {
                throw new Error(eventData.message);
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }

          boundary = buffer.indexOf('\n\n');
        }
      }

      if (buffer.length > 0) {
        console.warn('Unprocessed data in buffer:', buffer);
      }

      if (!finalData) {
        throw new Error('Stream ended without complete data');
      }

      if (finalData.final_user) {
        console.log("finalData.final_user", finalData.final_user);
        dispatch(updateUser(finalData.final_user));
      }

      console.log("finalData", JSON.stringify(finalData, null, 2));

      return { projectId, response: finalData };
    } catch (error) {
      if (error.type === 'SendMessageError') {
        dispatch(setSendMessageError(error.message));
      } else {
        dispatch(setError('An unexpected error occurred'));
      }
      throw error;
    } finally {
      dispatch(setIsChatLoading(false));
    }
  }
);

export const uploadFile = createAsyncThunk(
  "projects/uploadFile",
  async ({ files, projectId, chatId, userId, annotate }, { dispatch, getState }) => {
    dispatch(setIsLoadingFiles(true));
    try {
      const token = getState().user.access_token;
      const projectIdToUse = projectId === "no_project" ? null : projectId;

      // Upload files one by one
      const uploadedFiles = [];
      for (const fileData of files) {
        if (annotate) {
          dispatch(setLoadingMessage(`Uploading ${fileData.file.name}`));
          const uploadPromise = uploadFiles([fileData], projectIdToUse, chatId, userId, annotate, token);
          const timeoutPromise = new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second

          const response = await Promise.race([uploadPromise, timeoutPromise]);

          if (response) {
            uploadedFiles.push(response);
          } else {
            dispatch(setLoadingMessage(`Annotating ${fileData.file.name}`));
            const finalResponse = await uploadPromise;
            uploadedFiles.push(finalResponse);
          }
        } else {
          dispatch(setLoadingMessage(`Uploading ${fileData.file.name}`));
          const response = await uploadFiles([fileData], projectIdToUse, chatId, userId, annotate, token);
          uploadedFiles.push(response);
        }
      }

      // Combine responses if needed
      const combinedResponse = uploadedFiles.reduce((acc, curr) => ({
        ...acc,
        ...curr,
        files: { ...acc.files, ...curr.files }
      }), {});

      dispatch(updateUser(combinedResponse.user));
      return combinedResponse;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    } finally {
      dispatch(setIsLoadingFiles(false));
      dispatch(setLoadingMessage(""));
    }
  }
);

// Rename the thunk action
export const addFileToProject = createAsyncThunk(
  "projects/addFileToProject",
  async ({ fileId, projectId }, { getState }) => {
    const token = getState().user.access_token;  // Get the access token from the user slice of the Redux state
    const response = await addFileToProjectAPI(fileId, projectId, token);
    return { projectId, updatedProject: response };
  }
);

export const fetchArtifactData = createAsyncThunk(
  "projects/fetchArtifactData",
  async (file_id, { getState }) => {
    const token = getState().user.access_token;
    console.log("fetchArtifactData file_id", file_id, "token", token);
    try {
      const response = await fetchArtifact(file_id, token);
      console.log("fetchArtifactData response", response);

      // Determine the type based on the response and file extension
      let type = "sequence";
      if (response.file_name?.endsWith('.csv')) {
        type = "csv";
      } else if (response.content && typeof response.content === "string") {
        type = "markdown";
      } else if (response.design) {
        type = "design";
      }

      return {
        ...response,
        type: type,
        file_id: file_id
      };
    } catch (error) {
      console.error("Error in fetchArtifactData:", error);
      throw error;
    }
  }
);

export const editProtocolData = createAsyncThunk(
  "projects/editProtocolData",
  async ({ fileId, inputs }, { getState }) => {
    const token = getState().user.access_token;
    console.log("editProtocolData fileId", fileId, "inputs", inputs, "token", token);
    try {
      const response = await editProtocol(fileId, inputs, token);
      console.log("editProtocolData response", response);

      // Determine the type based on the response
      let type = "sequence";
      if (response.content && typeof response.content === "string") {
        type = "markdown";
      } else if (response.design) {
        type = "design";
      }

      return {
        ...response,
        type: type,
        file_id: fileId
      };
    } catch (error) {
      console.error("Error in editProtocolData:", error);
      throw error;
    }
  }
);

export const generateProjectInstructions = createAsyncThunk(
  "projects/generateInstructions",
  async ({ projectId, override, groupId }, { dispatch, getState, rejectWithValue }) => {
    const token = getState().user.access_token;
    dispatch(setIsGeneratingInstructions(true));
    dispatch(clearGenerateInstructionsError());

    try {
      const response = await generateInstructions(projectId, override, groupId, token);

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let finalData = null;
      let buffer = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        let boundary = buffer.indexOf('\n\n');

        while (boundary !== -1) {
          const line = buffer.slice(0, boundary);
          buffer = buffer.slice(boundary + 2);

          if (line.startsWith('data: ')) {
            try {
              const eventData = JSON.parse(line.slice(6));
              if (eventData.status === 'update') {
                console.log('Streamed update:', eventData.message);
                dispatch(setInstructionsLoadingMessage(eventData.message));
              } else if (eventData.status === 'complete') {
                console.log('Final data received');
                finalData = eventData.data;
              } else if (eventData.status === 'error') {
                throw new Error(eventData.message);
              }
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          }

          boundary = buffer.indexOf('\n\n');
        }
      }

      if (buffer.length > 0) {
        console.warn('Unprocessed data in buffer:', buffer);
      }

      if (!finalData) {
        throw new Error('Stream ended without complete data');
      }

      // Update user data
      if (finalData.final_user) {
        dispatch(updateUser(finalData.final_user));
      }

      // Update project data
      if (finalData.final_project) {
        dispatch(updateProject(finalData.final_project));
      }

      // Update design data
      if (finalData.final_design) {
        dispatch(updateDesign(finalData.final_design));
      }

      // Refresh usage credits after successful generation
      dispatch(fetchUserCredits());

      return finalData;
    } catch (error) {
      console.error("Error in generateProjectInstructions:", error);
      if (error.type === 'GenerateInstructionsError') {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unexpected error occurred while generating instructions');
    } finally {
      dispatch(setIsGeneratingInstructions(false));
      dispatch(setInstructionsLoadingMessage(""));
    }
  }
);

export const navigateDesign = createAsyncThunk(
  "projects/navigateDesign",
  async ({ projectId, designId, direction }, { getState }) => {
    const token = getState().user.access_token;
    const response = await navigateDesignAPI(projectId, designId, direction, token);
    return response;
  }
);

export const submitArtifactInputs = createAsyncThunk(
  "projects/submitArtifactInputs",
  async (inputs, { getState, dispatch }) => {
    const token = getState().user.access_token;
    const projectId = getState().project.currentProjectId;
    const response = await submitArtifactInputsAPI(inputs, projectId, token);
    return response;
  }
);

export const getOrderData = createAsyncThunk(
  "projects/getOrderData",
  async (projectId, { getState }) => {
    const token = getState().user.access_token;
    const project = getState().project.currentProject;
    
    // Filter files that match the criteria using the file keys
    const fileIds = Object.keys(project?.files || {}).filter(fileId => {
      const file = project.files[fileId];
      return file.file_type === "Output" && 
        (file.file_name.includes("Order Form") ||
         file.file_name.includes("PCR") ||
         file.file_name.includes("Digest") ||
         file.file_name.includes("Golden Gate") ||
         file.file_name.includes("Gibson") ||
         file.file_name.includes("Traditional"));
    });
    
    const response = await fetchOrderData(fileIds, projectId, token);
    return response.order_data;
  }
);

export const submitOrder = createAsyncThunk(
  "projects/submitOrder",
  async ({ projectId, orderData, sequenceVerify }, { getState }) => {
    const token = getState().user.access_token;
    const response = await submitOrderRequest(projectId, orderData, sequenceVerify, token);
    return response;
  }
);

// Project slice
const initialState = {
  projectList: [],
  current: null,
  currentProject: null,
  chat: {},
  isLoading: false,
  isChatLoading: false,
  error: null,
  artifact: null,
  modal: { 'name': '', 'data': null },
  isSidebarVisible: true, // Ensure this is set as needed
  design: null, // Add design variable to initial state
  currentStreamedUpdate: null, // Add currentStreamedUpdate field
  isLoadingFiles: false, // Add isLoadingFiles field
  loadingMessage: "", // Add loadingMessage field
  instructions: null, // Add instructions field
  sendMessageError: null,
  isGeneratingInstructions: false,
  instructionsLoadingMessage: "",
  generateInstructionsError: null,
  orderData: null,
};

const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    updateModal(state, action) {
      console.log('Updating modal:', action.payload);
      state.modal = action.payload;
    },
    closeModal(state) {
      console.log('Closing modal');
      state.modal = { 'name': '', 'data': null };
    },
    updateProjectWithNewFile(state, action) {
      const { projectId, files } = action.payload;
      state.projectList = state.projectList.map((p) => {
        if (p.project_id === projectId) {
          return { ...p, files };
        }
        return p;
      });
    },
    setCurrentProjectId(state, action) {
      state.currentProjectId = action.payload;
      state.currentProject = state.projectList.find(
        (project) => project.project_id === action.payload
      );
    },
    updateArtifact(state, action) {
      state.artifact = action.payload;
    },
    updateProjectList(state, action) {
      state.projectList = action.payload;
    },
    updateChat(state, action) {
      state.chat[action.payload.project_id] = action.payload;
    },
    resetChat(state, action) {
      const projectId = action.payload;
      state.chat[projectId] = {
        chat_id: null,
        project_id: projectId,
        chat_contents: [],
      };
    },
    toggleSidebar(state) {
      state.isSidebarVisible = !state.isSidebarVisible;
    },
    setSidebarVisibility(state, action) {
      state.isSidebarVisible = action.payload;
    },
    addUserMessage: (state, action) => {
      const { projectId, message } = action.payload;
      if (!state.chat[projectId]) {
        state.chat[projectId] = {
          chat_id: null,
          project_id: projectId,
          chat_contents: [],
        };
      }
      state.chat[projectId].chat_contents.push({
        sender: "user",
        message: message,
      });
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsChatLoading: (state, action) => {
      state.isChatLoading = action.payload;
    },
    setCurrentStreamedUpdate: (state, action) => {
      state.currentStreamedUpdate = action.payload;
    },
    setIsLoadingFiles: (state, action) => {
      state.isLoadingFiles = action.payload;
      console.log("Redux: isLoadingFiles set to", action.payload);
    },
    setLoadingMessage: (state, action) => {
      state.loadingMessage = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setInstructions: (state, action) => {
      state.instructions = action.payload;
    },
    setSendMessageError: (state, action) => {
      state.sendMessageError = action.payload;
    },
    clearSendMessageError: (state) => {
      state.sendMessageError = null;
    },
    setIsGeneratingInstructions: (state, action) => {
      state.isGeneratingInstructions = action.payload;
    },
    setInstructionsLoadingMessage: (state, action) => {
      state.instructionsLoadingMessage = action.payload;
    },
    setGenerateInstructionsError: (state, action) => {
      state.generateInstructionsError = action.payload;
    },
    clearGenerateInstructionsError: (state) => {
      state.generateInstructionsError = null;
    },
    updateProject(state, action) {
      const updatedProject = action.payload;
      state.projectList = state.projectList.map(project =>
        project.project_id === updatedProject.project_id ? updatedProject : project
      );
      if (state.currentProjectId === updatedProject.project_id) {
        state.currentProject = updatedProject;
      }
    },
    updateDesign(state, action) {
      state.design = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        // state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.projectList = action.payload;
        if (state.projectList.length > 0) {
          state.currentProjectId = state.projectList[0].project_id;
          state.currentProject = state.projectList[0];
        } else {
          state.currentProjectId = null;
          state.currentProject = null;
        }
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchChat.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchChat.fulfilled, (state, action) => {
        const { projectId, chat } = action.payload;
        state.chat[projectId] = chat;
        state.isChatLoading = false;
      })
      .addCase(fetchChat.rejected, (state, action) => {
        state.error = action.error.message;
        state.isChatLoading = false;
      })
      .addCase(fetchDesign.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchDesign.fulfilled, (state, action) => {
        const { projectId, design } = action.payload;
        console.log("fetchDesign fulfilled", design);
        state.design = design;
        if (design) {
          state.artifact = { ...design, isDesign: true };
        }
      })
      .addCase(fetchDesign.rejected, (state, action) => {
        state.isChatLoading = false;
        state.error = action.error.message;
      })
      .addCase(createNewProject.fulfilled, (state, action) => {
        state.projectList.unshift(action.payload);
        state.currentProjectId = action.payload.project_id;
        state.currentProject = action.payload;
      })
      .addCase(renameExistingProject.fulfilled, (state, action) => {
        const { projectId, newName } = action.payload;
        const project = state.projectList.find(
          (p) => p.project_id === projectId
        );
        if (project) {
          project.project_name = newName;
          if (state.currentProjectId === projectId) {
            state.currentProject = project;
          }
        }
      })
      .addCase(deleteExistingProject.fulfilled, (state, action) => {
        const { projectId, updatedProjects } = action.payload;
        state.projectList = updatedProjects;
        if (state.currentProjectId === projectId) {
          state.currentProjectId =
            updatedProjects.length > 0 ? updatedProjects[0].project_id : null;
          state.currentProject =
            updatedProjects.length > 0 ? updatedProjects[0] : null;
        }
      })
      .addCase(deleteProjectFile.fulfilled, (state, action) => {
        const { projectId, fileId } = action.payload;
        const project = state.projectList.find(
          (p) => p.project_id === projectId
        );

        if (project && project.files[fileId]) {
          delete project.files[fileId];
          state.projectList = state.projectList.map((p) => {
            if (p.project_id === projectId) {
              return { ...p };
            }
            return p;
          });

          if (state.currentProjectId === projectId) {
            state.currentProject = { ...project };
          }
        }
      })
      .addCase(renameFileGlobal.fulfilled, (state, action) => {
        const { fileId, newName } = action.payload;
        // update all files in projectList
        state.projectList = state.projectList.map((p) => {
          if (p.files[fileId]) {
            p.files[fileId].file_name = newName;
          }
          return p;
        });
        if (state.currentProject && state.currentProject.files[fileId]) {
          state.currentProject.files[fileId].file_name = newName;
        }
      })
      .addCase(sendChatMessage.fulfilled, (state, action) => {
        const { projectId, response } = action.payload;
        if (!state.chat[projectId]) {
          state.chat[projectId] = {
            chat_id: null,
            project_id: projectId,
            chat_contents: [],
          };
        }
        console.log("sendChatMessage response", response);

        if (response.chat_contents && response.chat_contents.length > 0) {
          const lastMessage = response.chat_contents[response.chat_contents.length - 1];
          state.chat[projectId].chat_contents.push(lastMessage);
        }

        state.chat[projectId].contains_new_file = response.contains_new_file;

        if (response.contains_new_file && response.updated_project) {
          const { files } = response.updated_project;
          state.projectList = state.projectList.map((p) => {
            if (p.project_id === projectId) {
              return { ...p, files };
            }
            return p;
          });
          state.currentProject = response.updated_project;
        }

        if (response.final_project) {
          state.projectList = state.projectList.map(project =>
            project.project_id === response.final_project.project_id ? response.final_project : project
          );

          if (state.currentProjectId === response.final_project.project_id) {
            state.currentProject = response.final_project;
          }
        }

        if (response.final_design) {
          state.design = response.final_design;
        }

        if (response.user_preferences) {
          state.userPreferences = response.user_preferences;
        }

        if (response.final_full_chat_messages) {
          state.chat[projectId].chat_contents = response.final_full_chat_messages;
        }

        // Clear the streamed update
        state.currentStreamedUpdate = null;
      })
      .addCase(sendChatMessage.rejected, (state, action) => {
        if (action.error.type === 'SendMessageError') {
          state.sendMessageError = action.error.message;
        } else {
          state.error = 'An unexpected error occurred while sending the message';
        }
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const response = action.payload;

        if (response.project_id && response.project_id !== "no_project") {
          const updatedProjectList = state.projectList.map(project => {
            if (project.project_id === response.project_id) {
              return { ...project, files: response.files };
            }
            return project;
          });

          state.projectList = updatedProjectList;

          if (state.currentProjectId === response.project_id) {
            state.currentProject = updatedProjectList.find(p => p.project_id === response.project_id);
          }
        }
      })
      .addCase(addFileToProject.fulfilled, (state, action) => {
        const { projectId, updatedProject } = action.payload;
        state.projectList = state.projectList.map(project =>
          project.project_id === projectId ? updatedProject : project
        );

        if (state.currentProjectId === projectId) {
          state.currentProject = updatedProject;
        }

        console.log("Project updated with new file:", updatedProject);
      })
      .addCase(fetchArtifactData.fulfilled, (state, action) => {
        state.artifact = action.payload;
      })
      .addCase(fetchArtifactData.rejected, (state, action) => {
        state.error = action.error.message;
        console.error("fetchArtifactData rejected:", action.error);
      })
      .addCase(editProtocolData.pending, (state) => {
        state.error = null;
      })
      .addCase(editProtocolData.fulfilled, (state, action) => {
        state.artifact = action.payload;
      })
      .addCase(editProtocolData.rejected, (state, action) => {
        state.error = action.error.message;
        console.error("editProtocolData rejected:", action.error);
      })
      .addCase(generateProjectInstructions.pending, (state) => {
        state.isGeneratingInstructions = true;
        state.generateInstructionsError = null;
      })
      .addCase(generateProjectInstructions.fulfilled, (state, action) => {
        state.isGeneratingInstructions = false;
        state.instructions = action.payload;
        state.generateInstructionsError = null;
      })
      .addCase(generateProjectInstructions.rejected, (state, action) => {
        state.isGeneratingInstructions = false;
        state.generateInstructionsError = action.payload;
      })
      .addCase(navigateDesign.fulfilled, (state, action) => {
        state.design = action.payload;
      })
      .addCase(navigateDesign.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(submitArtifactInputs.fulfilled, (state, action) => {
        state.artifact = {
          type: "markdown",
          content: action.payload
        };
      })
      .addCase(submitArtifactInputs.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(getOrderData.fulfilled, (state, action) => {
        state.orderData = action.payload;
      })
      .addCase(getOrderData.rejected, (state, action) => {
        console.error("Failed to fetch order data:", action.error);
        state.orderData = null;
      })
      .addCase(submitOrder.fulfilled, (state, action) => {
        // Optional: Add any state updates needed
      })
      .addCase(submitOrder.rejected, (state, action) => {
        console.error("Failed to submit order:", action.error);
      });
  }
});

export const {
  setCurrentProjectId,
  updateChat,
  resetChat,
  updateProjectList,
  updateArtifact,
  updateProjectWithNewFile,
  updateModal,
  closeModal,
  toggleSidebar,
  setSidebarVisibility,
  addUserMessage,
  setIsLoading,
  setIsChatLoading,
  setCurrentStreamedUpdate,
  setIsLoadingFiles,
  setLoadingMessage,
  setError,
  setInstructions,
  setSendMessageError,
  clearSendMessageError,
  setIsGeneratingInstructions,
  setInstructionsLoadingMessage,
  setGenerateInstructionsError,
  clearGenerateInstructionsError,
  updateProject,
  updateDesign,
} = projectSlice.actions;

export default projectSlice.reducer;