import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { SeqViz } from "seqviz";
import "../style/Sequence.css";
import { downloadFiles } from "../api.js";

function Sequence() {
  const artifact = useSelector((state) => state.project.artifact);
  const token = useSelector((state) => state.user.access_token);
  const [viewerType, setViewerType] = useState("circular");
  const [selectedSequence, setSelectedSequence] = useState(null);

  const handleDownload = () => {
    if (artifact && artifact.file_id) {
      downloadFiles(artifact.file_id, "file", token);
    } else {
      console.error("No file_id available for the current artifact");
    }
  };

  if (!artifact) {
    return null;
  }

  const sequenceNames = Object.keys(artifact).filter(
    (key) => key !== "type" && key !== "file_id" && key !== "protocol_id" && key !== "digest_vendor" && !key.startsWith("table_input_")
  );

  const currentSequence = selectedSequence || sequenceNames[0];
  const sequenceData = artifact[currentSequence];

  if (!sequenceData) {
    return null;
  }

  return (
    <div className="sequence-content">
      <div className="viewer-controls">
        <div className="dropdowns-container">
          <div className="viewer-type-container">
            <label className="viewer-type-label" htmlFor="viewer-type-dropdown">
              Viewer
            </label>
            <select
              id="viewer-type-dropdown"
              className="viewer-type-dropdown"
              value={viewerType}
              onChange={(e) => setViewerType(e.target.value)}
            >
              <option value="linear">Linear</option>
              <option value="circular">Circular</option>
              <option value="both">Both (Circular Left)</option>
              <option value="both_flip">Both (Circular Right)</option>
            </select>
          </div>

          {sequenceNames.length > 1 && (
            <div className="sequence-select-container">
              <label className="sequence-select-label" htmlFor="sequence-select-dropdown">
                Sequence
              </label>
              <select
                id="sequence-select-dropdown"
                className="sequence-select-dropdown"
                value={currentSequence}
                onChange={(e) => setSelectedSequence(e.target.value)}
              >
                {sequenceNames.map((name) => (
                  <option key={name} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <div className="protocol-button-group">
          <button className="protocol-download-button" title="Download" onClick={handleDownload}>
            <img src="/images/download.svg" alt="Download" className="protocol-download-icon" />
          </button>
        </div>
      </div>
      <SeqViz
        name={sequenceData.name || currentSequence}
        seq={sequenceData.seq}
        annotations={sequenceData.annotations}
        viewer={viewerType}
      />
    </div>
  );
}

export default Sequence;
