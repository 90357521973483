import React, { useState, useCallback, useEffect, useRef } from "react";
import "../style/ArtifactPanel.css";
import { useSelector, useDispatch } from "react-redux";
import {
  generateProjectInstructions,
  clearGenerateInstructionsError,
  updateArtifact,
  navigateDesign,
} from "../store/project.js";
import { BiFullscreen, BiExitFullscreen } from "react-icons/bi";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Design from "./Design.js";
import Protocol from "./Protocol.js";
import Sequence from "./Sequence.js";

function ArtifactPanel({ onClose }) {
  const artifact = useSelector((state) => state.project.artifact);
  const projectId = useSelector((state) => state.project.currentProjectId);
  const isSidebarVisible = useSelector((state) => state.project.isSidebarVisible);
  const [width, setWidth] = useState(61);
  const [isDragging, setIsDragging] = useState(false);
  const design = useSelector((state) => state.project.design);
  const dispatch = useDispatch();
  const [messagesWidth, setMessagesWidth] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const panelRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control popup visibility

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      const mainElement = document.querySelector(".main");
      if (mainElement) {
        mainElement.classList.add("panel-closed");
        mainElement.style.width = "0";
      }
      setIsFullscreen(!isFullscreen);
    } else {
      const mainElement = document.querySelector(".main");
      if (mainElement) {
        mainElement.classList.remove("panel-closed");
        mainElement.style.width = "";
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        const newWidth =
          ((window.innerWidth - e.clientX) / window.innerWidth) * 100 +
          (isSidebarVisible ? 10 : 0);
        setWidth(Math.max(20, Math.min(80, newWidth)));
      }
    },
    [isDragging, isSidebarVisible]
  );

  useEffect(() => {
    const chatContainer = document.querySelector(".chat-messages-container");
    if (chatContainer) {
      setMessagesWidth(chatContainer.offsetWidth);
    }
  }, []);

  const onCloseButtonClick = () => {
    if (isFullscreen) {
      toggleFullscreen();
    }
    onClose();
  };

  useEffect(() => {
    if (isDragging) {
      const chatContainer = document.querySelector(".chat-messages-container");
      if (chatContainer && chatContainer.offsetWidth !== messagesWidth) {
        // scrollToBottom();
        setMessagesWidth(chatContainer.offsetWidth);
      }
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp, messagesWidth]);

  if (!artifact) {
    return null;
  }

  const handleConfirmGenerateInstructions = () => {
    setIsPopupOpen(false); // Hide the popup
    dispatch(clearGenerateInstructionsError());
    dispatch(
      generateProjectInstructions({ projectId, override: "false" })
    ); 
  };

  const handleCancelGenerateInstructions = () => {
    setIsPopupOpen(false); 
  };

  const handleDesignClick = () => {
    if (design) {
      dispatch(updateArtifact({ ...design, isDesign: true }));
    }
  };

  const handleForwardClick = () => {
    dispatch(navigateDesign({ projectId: projectId, designId: design.labkick_id, direction: 'forward' }));
  };

  const handleBackClick = () => {
    dispatch(navigateDesign({ projectId: projectId, designId: design.labkick_id, direction: 'back' }));
  };


  let content;
  console.log(artifact.type);
  if (artifact.type === "html") {
    content = (
      <div
        className="html-content"
        dangerouslySetInnerHTML={{ __html: artifact.content }}
      />
    );
  } else if (artifact.isDesign) {
    content = <Design />;
  } else if (artifact.type === "markdown" || artifact.type === "csv") {
    content = <Protocol />;
  } else {
    content = <Sequence />;
  }

  return (
    <div
      ref={panelRef}
      className={`artifact-panel ${isFullscreen ? "fullscreen-panel" : ""}`}
      style={{ flexBasis: `${width}%`, maxWidth: `${width}%` }}
    >
      <div className="resize-handle" onMouseDown={handleMouseDown}></div>
      <div className="artifact-buttons-container">
        <button className="fullscreen-button" onClick={toggleFullscreen}>
          {isFullscreen ? (
            <BiExitFullscreen size={24} />
          ) : (
            <BiFullscreen size={24} />
          )}
        </button>
        {artifact && artifact.isDesign && (
          <>
            <button className="back-button" onClick={() => handleBackClick()}>
              <FaArrowLeft />
            </button>
            <button className="forward-button" onClick={() => handleForwardClick()}>
              <FaArrowRight />
            </button>
          </>
        )}
      </div>
      {artifact && !artifact.isDesign && (
        <button className="back-to-design-button" onClick={handleDesignClick}>
          <FaArrowLeft size={24} />
          Back to Design
        </button>
      )}
      <button className="close-button no-hover" onClick={onCloseButtonClick}>
        &times;
      </button>

      {isPopupOpen && (
        <div className="artifact-confirmation-popup-overlay">
          <div className="artifact-confirmation-popup-dialog">
            <h3>Generate Instructions</h3>
            <p>
              Are you ready to generate instructions? Once you generate
              instructions you will only be able to modify the design twice.
              Please make sure you have all your parts you need as part of
              your design.
            </p>
            <div className="artifact-confirmation-popup-buttons">
              <button
                onClick={handleConfirmGenerateInstructions}
                className="artifact-confirm-button"
              >
                Yes, Generate
              </button>
              <button
                onClick={handleCancelGenerateInstructions}
                className="artifact-cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="artifact-content">{content}</div>
    </div>
  );
}

export default ArtifactPanel;