import React, { useState, useEffect, useRef } from "react";
import { deleteProjectFile, updateModal, closeModal } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import "../style/Modal.css";  // Import the CSS file

function DeleteFileFromProjectModal({ isOpen }) {
    const [confirmValue, setConfirmValue] = useState("");
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const { modal, currentProjectId } = useSelector((state) => state.project);
    const user = useSelector((state) => state.user.data);
    const token = useSelector(state => state.user.access_token);

    const title = `Delete File: ${modal.data?.name || ''}`;
    const confirmText = "delete";

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                dispatch(updateModal({ name: '', data: null }));
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    useEffect(() => {
        setConfirmValue("");
    }, [isOpen]);

    const handleDeleteFileSubmit = async (e) => {
        e.preventDefault();
        if (confirmValue !== confirmText) {
            alert(`Please type "${confirmText}" to confirm.`);
            return;
        }
        try {
            dispatch(
                deleteProjectFile({
                    projectId: currentProjectId,
                    fileId: modal.data.uuid,
                    userId: user.user_id,
                })
            );
            dispatch(closeModal());
        } catch (error) {
            console.error("Error deleting file:", error);
        }
    };

    return (
        <div className="delete-file-modal-overlay" onClick={(e) => e.stopPropagation()}>
            <div className="delete-file-modal-content" ref={modalRef}>
                <h2 className="delete-file-modal-title">{title}</h2>
                <p>Are you sure you want to delete this file? This action cannot be undone.</p>
                <form onSubmit={handleDeleteFileSubmit}>
                    <input
                        type="text"
                        value={confirmValue}
                        onChange={(e) => setConfirmValue(e.target.value)}
                        placeholder={`Type "${confirmText}" to confirm`}
                        className="delete-file-input"
                    />
                    <div className="delete-file-modal-buttons">
                        <button type="button" onClick={() => dispatch(closeModal())} className="delete-file-cancel-button">
                            Cancel
                        </button>
                        <button type="submit" className="delete-file-submit-button">Delete</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default DeleteFileFromProjectModal;