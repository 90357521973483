import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { closeModal, uploadFile, updateModal, setIsLoadingFiles, setLoadingMessage } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import "../style/FileUploadModal.css";

function FileUploadModal() {
    const [files, setFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();
    const { modal, currentProjectId } = useSelector((state) => state.project);
    const user = useSelector((state) => state.user.data);
    const chat = useSelector((state) => state.project.chat)[currentProjectId];
    const chatId = chat.chat_id;
    const [annotateOption, setAnnotateOption] = useState("annotate");
    const [uploadType, setUploadType] = useState("file");
    const [sequenceName, setSequenceName] = useState("");
    const [sequence, setSequence] = useState("");
    const isLoadingFiles = useSelector((state) => state.project.isLoadingFiles);
    const [fileSpecs, setFileSpecs] = useState({});

    const maxNameLength = 50;
    const validNameRegex = /^[a-zA-Z0-9_:\-\.#$]+$/;

    const validateFileName = (fileName) => {
        if (fileName.length > maxNameLength) {
            return "File name must be 30 characters or less.";
        }
        if (fileName.split('.').length > 2) {
            return "File name must contain one or less period (.)";
        }
        if (!validNameRegex.test(fileName)) {
            return "File name contains invalid characters. Only letters, numbers, and _:-#$ are allowed.";
        }
        return null;
    };

    const onDrop = useCallback((acceptedFiles) => {
        setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }, []);

    const DNA_FILE_FORMAT = {
        ".fa": "fasta",
        ".fasta": "fasta",
        ".fna": "fasta",
        ".txt": "fasta",
        ".gb": "genbank",
        ".gbk": "genbank",
        ".genbank": "genbank",
        ".embl": "embl",
        ".fastq": "fastq",
        ".fq": "fastq",
        ".ig": "ig",
    }


    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleUploadTypeChange = (type) => {
        setUploadType(type);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let isValid = true;
        let errorMessages = [];

        if (uploadType === "file") {
            const invalidNames = files.map(file => {
                const error = validateFileName(file.name);
                return error ? `${file.name}: ${error}` : null;
            }).filter(Boolean);

            if (invalidNames.length > 0) {
                isValid = false;
                errorMessages.push(`Invalid file names detected:\n${invalidNames.join("\n")}`);
            }
        } else if (uploadType === "sequence") {
            const fileNameError = validateFileName(sequenceName);
            if (fileNameError) {
                isValid = false;
                errorMessages.push(fileNameError);
            }
        }

        if (!isValid) {
            setErrorMessage(errorMessages.join("\n"));
            return;
        }

        // If all validations pass, proceed with file upload
        console.log("handleFileUpload called with:", { currentProjectId, user, chatId, annotateOption });
        const filesWithSpecs = files.map(file => ({
            file,
            topology: fileSpecs[file.name]?.topology || 'circular',
            parentFileId: fileSpecs[file.name]?.parentFile || null
        }));
        try {
            const result = handleFileUpload(filesWithSpecs, currentProjectId, chatId, user.user_id, annotateOption);
            if (result) {
                dispatch(updateModal({ name: modal.name.replace('upload', 'dna_archive') }));
            }
        } catch (error) {
            setErrorMessage(error.message || "Failed to upload files. Please try again.");
        }
    };

    const DNA_FILE_EXTENSIONS = [
        ".fa", ".fasta", ".fna", ".gb", ".gbk", ".genbank", ".embl", ".fastq", ".fq", ".ig", ".txt"
    ];

    const handleFileUpload = (filesWithSpecs, projectId, chatId, userId, annotate) => {
        let filesToUpload = [];

        const userFiles = user.files || {};
        const existingFileStems = Object.values(userFiles).map(file => file.file_name.split('.')[0]);
        const userFilesLength = Object.keys(user.files).length;
        const maxSize = 5 * 1024 * 1024; // 5MB in bytes
        const maxSequenceLength = 5e6; // 5 million bases

        if (userFilesLength > 99) {
            setErrorMessage(`You have reached the maximum number of files allowed. Please delete some files.`);
            return false;
        }

        if (uploadType === "file") {
            const userFiles = user.files || {};
            // Extract file stems (names without extensions) from existing files
            const existingFileStems = Object.values(userFiles).map(file => file.file_name.split('.')[0]);
            // Check for duplicates based on file stems
            const duplicateFiles = filesWithSpecs.filter(fileData => existingFileStems.includes(fileData.file.name.split('.')[0]));

            console.log("userFiles", userFiles);
            console.log("existingFileStems", existingFileStems);
            console.log("files to upload", filesWithSpecs);
            console.log("duplicateFiles", duplicateFiles);

            if (duplicateFiles.length > 0) {
                setErrorMessage(`Duplicate file names detected: ${duplicateFiles.map(f => f.file.name).join(", ")}. Please rename these files.`);
                console.log("Duplicate files detected:", duplicateFiles);
                return false;
            }

            const totalSize = filesWithSpecs.reduce((acc, fileData) => acc + fileData.file.size, 0);
            const maxSize = 5 * 1024 * 1024; // 10MB in bytes

            if (totalSize > maxSize) {
                setErrorMessage("File upload size too big. Maximum total allowed size is 5MB.");
                return false;
            }

            const invalidFiles = filesWithSpecs.filter(fileData => {
                const extension = '.' + fileData.file.name.split('.').pop().toLowerCase();
                return !DNA_FILE_EXTENSIONS.includes(extension);
            });

            if (invalidFiles.length > 0) {
                setErrorMessage(`Invalid file types detected: ${invalidFiles.map(f => f.file.name).join(", ")}. 
                    Please upload files with the following extensions: ${DNA_FILE_EXTENSIONS.join(", ")}`);
                console.log("Invalid files detected:", invalidFiles);
                return false;
            }

            filesToUpload = filesWithSpecs;
        } else if (uploadType === "sequence") {
            if (!sequenceName || !sequence) {
                setErrorMessage("Please provide both a sequence name and sequence.");
                return false;
            }

            const cleanedSequence = sequence.replace(/[^atcgATCG]/g, '').toUpperCase();
            if (cleanedSequence.length === 0) {
                setErrorMessage("The sequence must contain valid DNA bases (A, T, C, G).");
                return false;
            }

            // Check file size
            if (cleanedSequence.length > maxSize) {
                setErrorMessage("Sequence is too long. Max allowed is 5 million bases.");
                return false;
            }

            // Check for duplicates using the sequenceName (without extension)
            console.log("existingFileStems", existingFileStems);
            console.log("sequenceName", sequenceName);
            if (existingFileStems.includes(sequenceName.split('.')[0])) {
                setErrorMessage(`Duplicate sequence name detected: ${sequenceName}. Please choose a different name.`);
                return false;
            }

            // Ensure the file name ends with .fasta
            const fileName = `${sequenceName.split('.')[0]}.fasta`;
            const fastaContent = `>${sequenceName.split('.')[0]}\n${cleanedSequence}`;
            const fastaFile = new File([fastaContent], fileName, { type: "text/plain" });



            filesToUpload = [{ file: fastaFile, topology: 'linear', parentFileId: null }];
        }

        setErrorMessage(""); // Clear error message if no issues

        try {
            if (modal.name === 'upload_general') {
                projectId = "no_project";
            }
            dispatch(setIsLoadingFiles(true));
            dispatch(uploadFile({ projectId, files: filesToUpload, chatId, userId, annotate }));
            return true;
        } catch (error) {
            console.error("Failed to upload files:", error);
            setErrorMessage("Failed to upload files. Please try again.");
            return false;
        }
    };

    const removeFile = (fileToRemove) => {
        setFiles(files.filter((file) => file !== fileToRemove));
    };

    const formatFileSize = (bytes) => {
        if (bytes < 1024) {
            return `${bytes} bytes`;
        } else if (bytes < 1024 * 1024) {
            return `${Math.floor(bytes / 1024)} KB`;
        } else {
            return `${Math.floor(bytes / (1024 * 1024))} MB`;
        }
    };

    const handleFileSpecChange = (file, spec, value) => {
        setFileSpecs(prev => ({
            ...prev,
            [file.name]: {
                ...prev[file.name],
                [spec]: value
            }
        }));
    };

    const renderFileSpecs = (file) => {
        const extension = '.' + file.name.split('.').pop().toLowerCase();
        const format = DNA_FILE_FORMAT[extension];
        const isGenBankOrEmbl = ['.gb', '.gbk', '.genbank', '.embl'].includes(extension);

        const parentFileOptions = [
            { value: '', label: 'No Parent' },
            ...Object.values(user.files || {}).map(userFile => ({
                value: userFile.dna_id,
                label: userFile.file_name
            }))
        ];

        // Determine the select value
        const selectValue = isGenBankOrEmbl
            ? 'specified'
            : (fileSpecs[file.name]?.topology || 'circular');

        return {
            topologyDropdown: (
                <select
                    className="topology-select"
                    value={selectValue}
                    onChange={(e) => handleFileSpecChange(file, 'topology', e.target.value)}
                    disabled={isGenBankOrEmbl}
                >
                    {!isGenBankOrEmbl && <option value="circular">Circular</option>}
                    {!isGenBankOrEmbl && <option value="linear">Linear</option>}
                    {isGenBankOrEmbl && (
                        <option value="specified" disabled>
                            N/A
                        </option>
                    )}
                </select>
            ),
            parentSequenceDropdown: (
                <Select
                    options={parentFileOptions}
                    value={parentFileOptions.find(option => option.value === fileSpecs[file.name]?.parentFile) || parentFileOptions[0]}
                    onChange={(selectedOption) => handleFileSpecChange(file, 'parentFile', selectedOption ? selectedOption.value : '')}
                    isClearable={false}
                    isSearchable
                    placeholder="Select parent file..."
                    className="parent-file-select"
                    classNamePrefix="parent-file-select"
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            backgroundColor: 'transparent'
                        }),
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: 'transparent',
                            borderColor: '#B9B9B9',
                            minHeight: '32px',
                            height: '32px',
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            color: 'black'
                        }),
                        option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? '#F28A22' : '#DFDFDF',
                            color: 'black',
                            cursor: 'pointer'
                        }),
                        dropdownIndicator: (provided) => ({
                            ...provided,
                            color: 'black'
                        }),
                        indicatorSeparator: () => ({
                            display: 'none'
                        })
                    }}
                />
            )
        };
    };

    const handleSequenceNameChange = (e) => {
        setSequenceName(e.target.value);
    };

    if (modal.name !== 'upload_general' && modal.name !== 'upload_project') {
        return null;
    }

    return (
        <div className="file-upload-modal-overlay">
            <div className="file-upload-modal-dialog">
                <div className="upload-type-toggle">
                    <button
                        className={`toggle-button ${uploadType === "file" ? "active" : ""}`}
                        onClick={() => handleUploadTypeChange("file")}
                    >
                        Upload File
                    </button>
                    <button
                        className={`toggle-button ${uploadType === "sequence" ? "active" : ""}`}
                        onClick={() => handleUploadTypeChange("sequence")}
                    >
                        Paste Sequence
                    </button>
                </div>
                {errorMessage && (
                    <div className="error-message">
                        <p>{errorMessage}</p>
                    </div>
                )}
                <form onSubmit={handleSubmit}>
                    {uploadType === "file" ? (
                        <>
                            <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p>Drop the files here ...</p>
                                ) : (
                                    <p>Drag and drop some files here, or click to select files</p>
                                )}
                            </div>
                            {files.length > 0 && (
                                <div className="file-list">
                                    <h3>Selected Files:</h3>
                                    <ul>
                                        {/* Header Row */}
                                        <li className="file-list-header">
                                            <div>File Name</div>
                                            <div>Topology</div>
                                            <div>Parent Sequence</div>
                                        </li>
                                        {/* File Rows */}
                                        {files.map((file, index) => (
                                            <li key={index}>
                                                <div>
                                                    {file.name} - {formatFileSize(file.size)}
                                                    <button type="button" onClick={() => removeFile(file)}>
                                                        Remove
                                                    </button>
                                                </div>
                                                <div>{renderFileSpecs(file).topologyDropdown}</div>
                                                <div>{renderFileSpecs(file).parentSequenceDropdown}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="sequence-input">
                            <input
                                type="text"
                                placeholder="Sequence Name"
                                value={sequenceName}
                                onChange={handleSequenceNameChange}
                            />
                            <textarea
                                placeholder="Sequence Text"
                                value={sequence}
                                onChange={(e) => setSequence(e.target.value)}
                            />
                        </div>
                    )}
                    <div className="annotation-options">
                        <div className="radio-group">
                            <label>
                                <input
                                    type="radio"
                                    value="annotate"
                                    checked={annotateOption === "annotate"}
                                    onChange={() => setAnnotateOption("annotate")}
                                />
                                Annotate files
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="no_annotate"
                                    checked={annotateOption === "no_annotate"}
                                    onChange={() => setAnnotateOption("no_annotate")}
                                />
                                Don't annotate
                            </label>
                        </div>
                        <div className="annotation-info">
                            We keep your sequences safe and annotate them on our servers. Annotation takes ~2-10 seconds per file. Please note that if you request your files to be annotated, we will save them as .gb files in LabKick.
                        </div>
                    </div>
                    <div className="file-upload-modal-buttons">
                        <button type="button" onClick={() => dispatch(updateModal({ name: modal.name.replace('upload', 'dna_archive') }))} className="file-upload-cancel-button">
                            Cancel
                        </button>
                        <button type="submit" className="file-upload-confirm-button">Upload</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FileUploadModal;