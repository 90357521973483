import React, { useState, useEffect, useRef } from "react";
import { createNewProject, updateModal, closeModal } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import "../style/Modal.css";  // Import the CSS file

function CreateProjectModal({ isOpen, initialValue = "" }) {
    const [inputValue, setInputValue] = useState(initialValue || "");
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const projectList = useSelector((state) => state.project.projectList);

    const title = "Create New Project";

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target)
            ) {
                dispatch(updateModal({ name: '', data: null }));
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    useEffect(() => {
        setInputValue(initialValue || "");
    }, [initialValue]);

    const handleCreateProjectSubmit = (projectName) => {
        const existingProject = projectList.find(project => project.project_name === projectName);
        if (existingProject) {
            alert("Project with this name already exists");
            return;
        }

        if (projectList.length >= 25) {
            alert("Maximum number of projects hit. Please delete some to create a new one.");
            return;
        }
        dispatch(createNewProject(projectName));
        dispatch(closeModal());
    };

    return (
        <div className="create-project-modal-overlay" onClick={(e) => e.stopPropagation()}>
            <div className="create-project-modal-content" ref={modalRef}>
                <h2 className="create-project-modal-title">{title}</h2>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleCreateProjectSubmit(inputValue);
                }}>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Enter name"
                        className="create-project-input"
                    />
                    <div className="create-project-modal-buttons">
                        <button type="button" onClick={() => dispatch(closeModal())} className="create-project-cancel-button">
                            Cancel
                        </button>
                        <button type="submit" className="create-project-submit-button">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateProjectModal;