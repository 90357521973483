import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as d3 from 'd3';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { FaMagic } from 'react-icons/fa';
import { MdOutlineHorizontalRule } from 'react-icons/md';
import { TbCircleDashed } from 'react-icons/tb';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { generateProjectInstructions, clearGenerateInstructionsError, navigateDesign, fetchArtifactData, updateModal, getOrderData } from '../store/project.js';
import { fetchGroups } from '../store/user.js';
import '../style/Design.css';
import Select from 'react-select';
import { useAuth0 } from '@auth0/auth0-react';
import { resendVerificationEmail } from '../api.js';

function Design() {
  const design = useSelector((state) => state.project.design);
  const svgRef = useRef(null);
  const svgContainerRef = useRef(null);
  const dispatch = useDispatch();
  const isGeneratingInstructions = useSelector((state) => state.project.isGeneratingInstructions);
  const generateInstructionsError = useSelector((state) => state.project.generateInstructionsError);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [tooltip, setTooltip] = useState({ visible: false, text: '', x: 0, y: 0 });
  const groupsObj = useSelector((state) => state.user.groups);
  const [selectedGroupId, setSelectedGroupId] = useState('personal');
  const user = useSelector((state) => state.user);
  const usageCredits = useSelector((state) => state.user.usageCredits);

  const projectId = useSelector((state) => state.project.currentProjectId);
  const project = useSelector((state) => state.project.projectList.find((p) => p.project_id === projectId));

  const colorScale = React.useMemo(() => scaleOrdinal(schemeCategory10), []);
  const token = useSelector((state) => state.user.access_token);

  // Calculate personal credits (total - sum of group credits)
  const personalCredits = useMemo(() => {
    const totalCredits = usageCredits || 0;
    const groupCredits = Object.values(groupsObj || {}).reduce((sum, group) => sum + (group.num_credits || 0), 0);
    return totalCredits - groupCredits;
  }, [usageCredits, groupsObj]);

  // Transform groups into options format for React Select
  const groupOptions = useMemo(() => {
    const options = [
      { value: 'personal', label: `Personal (${personalCredits} credits)` }
    ];

    if (groupsObj) {
      Object.values(groupsObj).forEach(group => {
        options.push({
          value: group.group_id,
          label: `${group.name} (${group.num_credits} credits)`
        });
      });
    }

    return options;
  }, [groupsObj, personalCredits]);

  const { getAccessTokenSilently, user: userData } = useAuth0();

  useEffect(() => {
    if (svgRef.current && design) {
      renderDesign();
    }
  }, [design]);

  useEffect(() => {
    const tooltipEl = document.createElement('div');
    tooltipEl.className = 'custom-tooltip';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.display = 'none';
    tooltipEl.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    tooltipEl.style.color = '#dfdfdf';
    tooltipEl.style.padding = '5px';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.fontSize = '12px';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.zIndex = '1000';
    document.body.appendChild(tooltipEl);

    return () => {
      document.body.removeChild(tooltipEl);
    };
  }, []);

  useEffect(() => {
    const tooltipEl = document.querySelector('.custom-tooltip');
    if (tooltip.visible) {
      tooltipEl.style.display = 'block';
      tooltipEl.style.left = `${tooltip.x}px`;
      tooltipEl.style.top = `${tooltip.y}px`;
      tooltipEl.innerHTML = tooltip.text;
    } else {
      tooltipEl.style.display = 'none';
    }
  }, [tooltip]);

  useEffect(() => {
    if (isPopupOpen) {
      dispatch(fetchGroups());
    }
  }, [isPopupOpen, dispatch]);

  const renderDesign = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    const containerWidth = Math.min(
      svgRef.current.parentNode.offsetWidth,
      1000
    );
    const binWidth = 180;
    const padding = 20;
    const leftPadding = 80;
    const topPadding = 10;
    const partRectWidth = binWidth - 10;
    const partRectHeight = 30;
    const partSpacing = 5;
    const arrowOffset = 60;
    const arrowLineWidth = 3;
    const arrowHeadSize = 4;
    const arrowHeadOffset = 10;
    const circularPathPadding = 100;
    const borderRadius = 3;
    const backgroundColor = "#f1f1f1";

    let customColors = [
      "#f28a22",
      "#d93dea",
      "#270C56",
      "#9370DB",
      "#53A3BE",
      "#336F3D",
    ];

    // Calculate dynamic bin height
    const maxParts = Math.max(
      ...design.bins.map((bin) => Object.keys(bin.part_names).length)
    );
    const binHeight = maxParts * (partRectHeight + partSpacing) + 40; // 40 for padding

    const totalWidth = Math.max(
      design.bins.length * (binWidth + 65) + leftPadding,
      containerWidth
    );

    // Calculate the actual width of the diagram
    const diagramWidth = design.bins.length * (binWidth + 60) + leftPadding;

    const getColor = (i, d) => {
      if (i < customColors.length) {
        return customColors[i];
      }
      return colorScale(i);
    };

    let maxBinHeight = 0;
    design.bins.forEach((bin) => {
      const binHeight =
        Object.keys(bin.part_names).length * (partRectHeight + partSpacing) + 40;
      maxBinHeight = Math.max(maxBinHeight, binHeight);
    });

    const totalHeight =
      maxBinHeight +
      topPadding +
      (design.design_type === "circular" ? circularPathPadding : 40);

    // Center the diagram horizontally
    const xOffset = (totalWidth - diagramWidth) / 2;

    const aspectRatio = totalWidth / totalHeight;

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
      };
    };

    svg.attr("viewBox", `0 0 ${totalWidth} ${totalHeight}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .style("width", "100%")
      .style("height", "100%");

    let keyboxPaddingLeft = 20;
    let keyboxPaddingTop = 0;
    // Add key box
    const keyBox = svg
      .append("g")
      .attr(
        "transform",
        `translate(${keyboxPaddingLeft}, ${keyboxPaddingTop})`
      );

    const typeIcon = keyBox
      .append("g")
      .attr("transform", `translate(100, 38)`);

    console.log(design.design_type);

    if (design.bins.length === 0) {
      svg
        .append("text")
        .attr("x", leftPadding)
        .attr("y", topPadding)
        .text("No bins created. Use the chat to create your design!")
        .style("font-size", "14px")
        .style("fill", "#111");
    }

    const binGroup = svg
      .append("g")
      .attr(
        "transform",
        `translate(${leftPadding + xOffset}, ${topPadding + 40})`
      );

    svg
      .append("defs")
      .append("marker")
      .attr("id", "arrowhead")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 5)
      .attr("refY", 0)
      .attr("markerWidth", arrowHeadSize)
      .attr("markerHeight", arrowHeadSize)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M0,-5L10,0L0,5")
      .attr("fill", "#111");

    design.bins.forEach((bin, index) => {
      let binHeight =
        Object.keys(bin.part_names).length * (partRectHeight + partSpacing) + 40;

      let binHeightOffset = (maxBinHeight - binHeight) / 2;

      // Check if the bin is a backbone and modify the name if necessary
      let binName = bin.name;
      if (design.design_type === "primer") {
        if (bin.is_backbone) {
          binName += " (template)";
        } else {
          binName += " (extension)";
        }
      } else if (bin.is_backbone && !bin.name.toLowerCase().includes("backbone")) {
        binName += " (backbone)";
      }

      const binG = binGroup
        .append("g")
        .attr(
          "transform",
          `translate(${index * (binWidth + 60)}, ${binHeightOffset})`
        );

      const binInteractionG = binG.append("g").style("cursor", "pointer");

      binInteractionG
        .append("rect")
        .attr("width", binWidth)
        .attr("height", binHeight)
        .attr("fill", "none")
        .attr("stroke-opacity", 0.1)
        .attr("stroke", getColor(index, bin));

      binG
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", binWidth)
        .attr("height", 0)
        .attr("fill", getColor(index, bin))
        .attr("fill-opacity", 0.2);

      binG
        .append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", binWidth)
        .attr("y2", 0)
        .attr("stroke", getColor(index, bin))
        .attr("stroke-width", 2);

      binG
        .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", binWidth)
        .attr("height", binHeight)
        .attr("fill", getColor(index, bin))
        .attr("opacity", 0.05);

      binG
        .append("text")
        .attr("x", binWidth / 2)
        .attr("y", -10)
        .attr("text-anchor", "middle")
        .text(`${binName}${bin.optional ? " (optional)" : ""}`)
        .style("font-size", "12px")
        .style("font-weight", "500")
        .attr("fill", "#111")
        .style("pointer-events", "none");

      const partGroup = binG
        .append("g")
        .attr("transform", `translate(5, 20)`);

      Object.entries(bin.part_names).forEach(([partName, partType], partIndex) => {
        const partG = partGroup
          .append("g")
          .datum(partName)
          .attr(
            "transform",
            `translate(0, ${partIndex * (partRectHeight + partSpacing)})`
          );

        const partInteractionG = partG.append("g").style("cursor", "pointer");

        partInteractionG.on("click.open", (event, d) => {
          handleClick(d);
        });

        const getPartSource = (type) => {
          switch (type) {
            case "synthesis":
            case "synthesis_to_order":
              return "Synthesis";
            case "pcr":
              return "PCR";
            case "digest":
              return "Digest";
            default:
              return type.charAt(0).toUpperCase() + type.slice(1);
          }
        };

        const handleMouseOver = (event) => {
          const rect = event.target.getBoundingClientRect();
          const tooltipText = `
            ${partName}<br>
            Source: ${getPartSource(partType)}
            ${partType === "synthesis_to_order" ? "<br>To Order" : ""}
          `;
          setTooltip({
            visible: true,
            text: tooltipText,
            x: rect.left + window.scrollX,
            y: rect.top + window.scrollY - 30,
          });
          d3.select(event.target).attr("fill", "#e2e2e2");
          d3.select(event.target).attr("stroke-opacity", 1);
        };

        const handleMouseOut = (event) => {
          setTooltip({ visible: false, text: "", x: 0, y: 0 });
          d3.select(event.target).attr("stroke-opacity", 0.5);
          d3.select(event.target).attr("fill", partType === "synthesis_to_order" ? "#e6f3ff" : backgroundColor);
        };

        const rect = partInteractionG
          .append("rect")
          .attr("width", partRectWidth)
          .attr("height", partRectHeight)
          .on("mouseover", handleMouseOver)
          .on("mouseout", handleMouseOut)
          .style("pointer-events", "all")
          .attr("fill", partType === "synthesis_to_order" ? "#e6f3ff" : backgroundColor)
          .attr("stroke", getColor(index, bin))
          .attr("stroke-opacity", 0.5)
          .attr("rx", borderRadius)
          .attr("ry", borderRadius);

        // Add SVG icon
        const iconWidth = 30;
        const iconHeight = 30;
        const iconX = 5;
        const iconY = (partRectHeight - iconHeight) / 2;

        let iconPath;
        switch (partType) {
          case "pcr":
            iconPath = "/images/pcr_rep.svg";
            break;
          case "digest":
            iconPath = "/images/digest_rep.svg";
            break;
          case "synthesis":
          case "synthesis_to_order":
          default:
            iconPath = "/images/synth_rep.svg";
            break;
        }

        partG
          .append("image")
          .attr("x", iconX)
          .attr("y", iconY)
          .attr("width", iconWidth)
          .attr("height", iconHeight)
          .attr("href", iconPath);

        partG
          .append("text")
          .attr("x", iconWidth + 10)
          .attr("y", partRectHeight / 2)
          .attr("dominant-baseline", "middle")
          .text(partName)
          .attr("fill", "#111")
          .style("font-size", "12px")
          .style("pointer-events", "none")
          .each(function () {
            const self = d3.select(this);
            let textLength = self.node().getComputedTextLength();
            let text = self.text();
            while (textLength > partRectWidth - iconWidth - 15 && text.length > 0) {
              text = text.slice(0, -1);
              self.text(text + "...");
              textLength = self.node().getComputedTextLength();
            }
          });
      });

      if (index < design.bins.length - 1) {
        const arrowStart = (index + 1) * (binWidth + 60) - arrowOffset;
        const arrowEnd = (index + 1) * (binWidth + 60) - arrowHeadOffset;

        binGroup
          .append("line")
          .attr("x1", arrowStart)
          .attr("y1", binHeight / 2 + binHeightOffset)
          .attr("x2", arrowEnd)
          .attr("y2", binHeight / 2 + binHeightOffset)
          .attr("stroke", "#111")
          .attr("stroke-width", arrowLineWidth)
          .attr("marker-end", "url(#arrowhead)");
      }
    });
    if (design.design_type === "circular" && design.bins.length > 0) {
      const lastBinX = (design.bins.length - 1) * (binWidth + 60);
      const elbowBottom = binHeight + circularPathPadding / 2;
      const elbowLeft = -arrowOffset;
      const curveRadius = 30; // Adjust this value to change the curve sharpness

      const pathData = `M${lastBinX + binWidth},${binHeight / 2}
                        L${lastBinX + binWidth + arrowOffset - curveRadius},${binHeight / 2
        }
                        Q${lastBinX + binWidth + arrowOffset},${binHeight / 2
        } ${lastBinX + binWidth + arrowOffset},${binHeight / 2 + curveRadius}
                        L${lastBinX + binWidth + arrowOffset},${elbowBottom - curveRadius
        }
                        Q${lastBinX + binWidth + arrowOffset
        },${elbowBottom} ${lastBinX + binWidth + arrowOffset - curveRadius
        },${elbowBottom}
                        L${elbowLeft + curveRadius},${elbowBottom}
                        Q${elbowLeft},${elbowBottom} ${elbowLeft},${elbowBottom - curveRadius
        }
                        L${elbowLeft},${binHeight / 2 + curveRadius}
                        Q${elbowLeft},${binHeight / 2} ${elbowLeft + curveRadius
        },${binHeight / 2}
                        L${arrowHeadOffset - 20},${binHeight / 2}`;

      binGroup
        .append("path")
        .attr("d", pathData)
        .attr("fill", "none")
        .attr("stroke", "#111")
        .attr("stroke-width", arrowLineWidth)
        .attr("marker-end", "url(#arrowhead)");
    }



    return (
      <div
        className="design-content"
        style={{ overflowY: "auto", height: "100%", paddingTop: "20px" }}
      >
        <div className="design-specification">
          <div className="design-specification-box">
            <div className="header">Design Specification</div>
            <p>
              <span className="label">Design Type</span>{" "}
              {design.design_type === "circular" && (
                <span className="icon circle">
                  <TbCircleDashed />
                </span>
              )}
              {design.design_type === "linear" && (
                <span className="icon line">
                  <MdOutlineHorizontalRule />
                </span>
              )}
              <span>
                {design.design_type.charAt(0).toUpperCase() +
                  design.design_type.slice(1)}
              </span>
            </p>
            <p>
              <span className="label">Assembly Method</span>{" "}
              <span>
                {design.assembly_method_type.toLowerCase() === 'golden_gate' ? 'Golden Gate' :
                  design.assembly_method_type.toLowerCase() === 'gibson' ? 'Gibson' :
                    design.assembly_method_type.toLowerCase() === 'traditional' ? 'Traditional' :
                      design.assembly_method_type.charAt(0).toUpperCase() + design.assembly_method_type.slice(1)}
              </span>
            </p>
            <p>
              <span className="label">Assembly Mode</span>{" "}
              <span>
                {design.assembly_mode.charAt(0).toUpperCase() +
                  design.assembly_mode.slice(1)}
              </span>
            </p>
            <p>
              <span className="label">Restriction Enzymes</span>{" "}
              <span>{design.restriction_enzymes ? design.restriction_enzymes.join(", ") : "None"}</span>
            </p>
          </div>
        </div>
        <div
          className="design-content-container"
          ref={svgContainerRef}
          style={{ width: "100%", height: "500px" }}
        >
          <svg ref={svgRef}></svg>
        </div>
        {design && design.ready_to_clone && (
          <div className="instructions-section">
            <button
              className="generate-instructions-button"
              onClick={handleGenerateInstructions}
              disabled={isGeneratingInstructions}
            >
              <FaMagic size={24} />
              <span>
                {isGeneratingInstructions
                  ? "Generating..."
                  : (project?.files && Object.values(project.files).some(file => file.file_type === "Output"))
                    ? "Regenerate Instructions"
                    : "Generate Instructions"}
              </span>
            </button>
            {generateInstructionsError && (
              <div className="design-error-message">{generateInstructionsError}</div>
            )}

            {project?.files &&
              Object.values(project.files).some(file =>
                file.file_type === "Output" &&
                file.file_name.includes("Synthesis")
              ) && (
                <button
                  className="generate-instructions-button"
                  onClick={handleOrderDNA}
                  style={{ marginTop: '10px' }}
                >
                  <FaMagic size={24} />
                  <span>Execute Protocol</span>
                </button>
              )}
          </div>
        )}
      </div>
    );
  };


  const handleClick = (d) => {
    console.log(project.files);
    // if tooltip, hide
    setTooltip({ visible: false, text: "", x: 0, y: 0 });
    // find the key of the object in files where files[key].file_name is equal to d
    const key = Object.keys(project.files).find(
      (key) => project.files[key].file_name === d
    );
    console.log(key);

    if (key) {
      dispatch(fetchArtifactData(key));
    }
  };


  const handleGenerateInstructions = async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch('https://login.labkick.ai/userinfo', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const userData = await response.json();
      console.log("userData REFRESH", userData);


      if (!userData.email_verified) {
        setIsEmailVerificationPopupOpen(true);
        return;
      }

      // If email is verified, proceed with showing the group selection popup
      setIsPopupOpen(true);

    } catch (error) {
      console.error('Error checking email verification:', error);
      dispatch(clearGenerateInstructionsError());
      dispatch(generateProjectInstructions({
        projectId,
        error: "Error checking email verification status"
      }));
    }
  };

  const handleConfirmGenerateInstructions = () => {
    setIsPopupOpen(false);
    dispatch(clearGenerateInstructionsError());
    dispatch(generateProjectInstructions({
      projectId,
      override: 'false',
      groupId: selectedGroupId === 'personal' ? null : selectedGroupId
    }));
  };

  const handleCancelGenerateInstructions = () => {
    setIsPopupOpen(false);
  };


  // Add new state for email verification popup
  const [isEmailVerificationPopupOpen, setIsEmailVerificationPopupOpen] = useState(false);

  // Add new handler for resend verification
  const handleResendVerification = async () => {
    try {
      const token = await getAccessTokenSilently();
      await resendVerificationEmail(userData, token);
      setIsEmailVerificationPopupOpen(false);
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
  };

  const orderData = useSelector((state) => state.project.orderData);

  const handleOrderDNA = async () => {
    try {
      const result = await dispatch(getOrderData(projectId)).unwrap();
      console.log("Order data:", result);

      dispatch(updateModal({
        name: 'order_dna',
        data: {
          name: design.name,
          total_constructs: result?.total_constructs || 0,
          synthesis_cost: result?.synthesis_cost || 0,
          labor_cost: result?.labor_cost || 0,
          protocol_cost: result?.protocol_cost || 0,
          total_price: result?.total_price || 0,
          lead_time: "5 business days",
          beta_message: "Protocol execution is still in a closed beta. To join the beta, and have LabKick assemble your DNA for you, please click the 'Submit Request' button below and our team will reach out shortly"
        }
      }));
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };

  const calculateCredits = (numConstructs, isRegenerating) => {
    const isPrimer = design.design_type === 'primer';
    
    if (isPrimer) {
      if (numConstructs <= 8) return 1;
      if (numConstructs <= 24) return 2;
      return 3;
    }
    
    // For other design types
    let credits;
    if (numConstructs <= 4) credits = 5;
    else if (numConstructs <= 8) credits = 10;
    else if (numConstructs <= 24) credits = 20;
    else credits = 30;
    
    return isRegenerating ? Math.ceil(credits / 5) : credits;
  };

  const calculateNumConstructs = () => {
    return design.bins.reduce((total, bin) => {
      const numParts = Object.keys(bin.part_names).length + (bin.optional ? 1 : 0);
      return total === 0 ? numParts : total * numParts;
    }, 0);
  };

  return (
    <div className="design-content">
      <div className="design-specification">
        <div className="design-specification-box">
          <div className="header">Design Specification</div>
          <p>
            <span className="label">Design Type</span>{' '}
            {design.design_type === 'circular' && (
              <span className="icon circle">
                <TbCircleDashed />
              </span>
            )}
            {design.design_type === 'linear' && (
              <span className="icon line">
                <MdOutlineHorizontalRule />
              </span>
            )}
            <span>
              {design.design_type.charAt(0).toUpperCase() + design.design_type.slice(1)}
            </span>
          </p>
          <p>
            <span className="label">Assembly Method</span>{' '}
            <span>
              {design.assembly_method_type.toLowerCase() === 'golden_gate'
                ? 'Golden Gate'
                : design.assembly_method_type.toLowerCase() === 'gibson'
                  ? 'Gibson'
                  : design.assembly_method_type.toLowerCase() === 'traditional'
                    ? 'Traditional'
                    : design.assembly_method_type.charAt(0).toUpperCase() + design.assembly_method_type.slice(1)}
            </span>
          </p>
          <p>
            <span className="label">Assembly Mode</span>{' '}
            <span>
              {design.assembly_mode.charAt(0).toUpperCase() + design.assembly_mode.slice(1)}
            </span>
          </p>
          <p>
            <span className="label">Restriction Enzymes</span>{' '}
            <span>{design.restriction_enzymes ? design.restriction_enzymes.join(', ') : 'None'}</span>
          </p>
        </div>
      </div>
      <div className="design-content-container" ref={svgContainerRef}>
        <svg ref={svgRef}></svg>
      </div>
      {design && design.ready_to_clone && (
        <div className="instructions-section">
          <button
            className="generate-instructions-button"
            onClick={handleGenerateInstructions}
            disabled={isGeneratingInstructions}
          >
            <FaMagic size={24} />
            <span>
              {isGeneratingInstructions
                ? "Generating..."
                : (project?.files && Object.values(project.files).some(file => file.file_type === "Output"))
                  ? "Regenerate Instructions"
                  : "Generate Instructions"}
            </span>
          </button>
          {generateInstructionsError && <div className="design-error-message">{generateInstructionsError}</div>}

          {project?.files &&
            Object.values(project.files).some(file =>
              file.file_type === "Output" &&
              file.file_name.includes("Synthesis")
            ) && (
              <button
                className="generate-instructions-button"
                onClick={handleOrderDNA}
                style={{ marginTop: '10px' }}
              >
                <FaMagic size={24} />
                <span>Execute Protocol</span>
              </button>
            )}
        </div>
      )}
      {isPopupOpen && (
        <div className="artifact-confirmation-popup-overlay">
          <div className="artifact-confirmation-popup-dialog">
            <div className="artifact-confirmation-popup-header">
              <h2>
                {project?.files && Object.values(project.files).some(file => file.file_type === "Output")
                  ? "Regenerate Instructions"
                  : "Generate Instructions"}
              </h2>
              <button className="artifact-confirmation-popup-close" onClick={handleCancelGenerateInstructions}>×</button>
            </div>
            
            <div className="artifact-confirmation-popup-content">
              <div className="artifact-confirmation-popup-form">
                <div className="artifact-confirmation-popup-summary">
                  <div className="artifact-confirmation-popup-summary-item">
                    <span>Number of constructs</span>
                    <span className="artifact-confirmation-popup-value-light">{calculateNumConstructs()}</span>
                  </div>
                  <div className="artifact-confirmation-popup-summary-item">
                    <span>Required credits</span>
                    <span className="artifact-confirmation-popup-value-bold">
                      {calculateCredits(calculateNumConstructs(), project?.files && Object.values(project.files).some(file => file.file_type === "Output"))}
                    </span>
                  </div>
                  <div className="artifact-confirmation-popup-summary-item">
                    <span>Total available credits</span>
                    <span className="artifact-confirmation-popup-value-light">{usageCredits}</span>
                  </div>
                </div>

                {/* Group selection section - moved above the table */}
                {Object.keys(groupsObj || {}).length > 0 && (
                  <div className="artifact-confirmation-group-section">
                    <div className="group-selection-label">Please choose which group you would like to use a credit from</div>
                    <Select
                      value={groupOptions.find(option => option.value === selectedGroupId)}
                      onChange={(option) => setSelectedGroupId(option.value)}
                      options={groupOptions}
                      className="protocol-dropdown"
                      classNamePrefix="protocol-dropdown"
                      isSearchable={false}
                    />
                  </div>
                )}

                <div className="artifact-confirmation-credits-table">
                  <h3>Credit Usage Guide</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Constructs</th>
                        <th>Assembly Gen</th>
                        <th>Assembly Edit</th>
                        <th>Primer Gen</th>
                        <th>Primer Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1-4</td>
                        <td>5</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>5-8</td>
                        <td>10</td>
                        <td>2</td>
                        <td>1</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>9-24</td>
                        <td>20</td>
                        <td>4</td>
                        <td>2</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>25+</td>
                        <td>30</td>
                        <td>6</td>
                        <td>3</td>
                        <td>1</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="artifact-confirmation-popup-buttons">
              <button onClick={handleCancelGenerateInstructions} className="artifact-cancel-button">
                Cancel
              </button>
              <button onClick={handleConfirmGenerateInstructions} className="artifact-confirm-button">
                {project?.files && Object.values(project.files).some(file => file.file_type === "Output")
                  ? "Yes, Regenerate"
                  : "Yes, Generate"}
              </button>
            </div>
          </div>
        </div>
      )}
      {isEmailVerificationPopupOpen && (
        <div className="artifact-confirmation-popup-overlay">
          <div className="artifact-confirmation-popup-dialog">
            <h3>Email Not Verified</h3>
            <p>Please verify your email before generating instructions. Check your junk mailbox if you don't see a verification email.</p>
            <div className="artifact-confirmation-popup-buttons">
              <button
                onClick={handleResendVerification}
                className="artifact-confirm-button"
              >
                Resend Verification Email
              </button>
              <button
                onClick={() => setIsEmailVerificationPopupOpen(false)}
                className="artifact-cancel-button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Design;