import React, { useRef, useEffect, useState } from "react";
import "../style/UserPreferencesModal.css";
import { saveUserPreferences, getUserPreferences } from "../api.js";
import { useSelector, useDispatch } from "react-redux";
import { updateModal } from "../store/project.js";
import { 
    pcr_protocol_list, 
    digest_protocol_list, 
    synthesis_to_order_protocol_list, 
    synthesis_to_order_gene_protocol_list, 
    synthesis_to_order_primer_protocol_list, 
    gibson_protocol_list, 
    golden_gate_protocol_list, 
    traditional_protocol_list 
} from "../store/protocol_list.js";

function UserPreferencesModal() {
    const modalRef = useRef(null);
    const [editMode, setEditMode] = useState({});
    const [userPrefs, setUserPrefs] = useState(null);
    const [preferencesLoaded, setPreferencesLoaded] = useState(false);
    const { modal } = useSelector((state) => state.project);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const token = useSelector(state => state.user.access_token);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [selectedProtocol, setSelectedProtocol] = useState('q5_high_fidelity_neb');
    const [selectedDigestVendor, setSelectedDigestVendor] = useState('NEB');
    const [selectedSynthesisProtocol, setSelectedSynthesisProtocol] = useState('synthesis_to_order_idt_gene');
    const [selectedSynthesisPrimerProtocol, setSelectedSynthesisPrimerProtocol] = useState('synthesis_to_order_idt_primer');
    const [selectedGibsonProtocol, setSelectedGibsonProtocol] = useState('nebuilder_hifi_neb');
    const [selectedGoldenGateProtocol, setSelectedGoldenGateProtocol] = useState('nebridge_bsmbiv2_neb');
    const [selectedTraditionalProtocol, setSelectedTraditionalProtocol] = useState('t4_dna_ligation_neb');
    const [selectedGibsonRegeneration, setSelectedGibsonRegeneration] = useState('None');
    const [gibsonHomologyLength, setGibsonHomologyLength] = useState('40');

    // Determine if the modal is open based on Redux state

    useEffect(() => {
        const fetchUserPreferences = async () => {
            if (!preferencesLoaded && user) {
                try {
                    const preferences = await getUserPreferences(user.user_id, token);
                    setUserPrefs({
                        ...preferences,
                        cloning_preferences: {
                            ...preferences.cloning_preferences,
                            pcr_protocol: preferences.cloning_preferences?.pcr_protocol || 'q5_high_fidelity_neb',
                            pcr_reaction_size: preferences.cloning_preferences?.pcr_reaction_size || '20',
                            primer_concentration: preferences.cloning_preferences?.primer_concentration || '10',
                            digest_vendor: preferences.cloning_preferences?.digest_vendor || 'NEB',
                            digest_reaction_size: preferences.cloning_preferences?.digest_reaction_size || '10',
                            synthesis_to_order_protocol: preferences.cloning_preferences?.synthesis_to_order_protocol || 'synthesis_to_order_idt_gene',
                            synthesis_to_order_primer_protocol: preferences.cloning_preferences?.synthesis_to_order_primer_protocol || 'synthesis_to_order_idt_primer',
                            gibson_protocol: preferences.cloning_preferences?.gibson_protocol || 'nebuilder_hifi_neb',
                            gibson_reaction_size: preferences.cloning_preferences?.gibson_reaction_size || '50',
                            gibson_restriction_site_regeneration: preferences.cloning_preferences?.gibson_restriction_site_regeneration || 'None',
                            gibson_default_homology_length: preferences.cloning_preferences?.gibson_default_homology_length || '40',
                            golden_gate_protocol: preferences.cloning_preferences?.golden_gate_protocol || 'nebridge_bsmbiv2_neb',
                            golden_gate_reaction_size: preferences.cloning_preferences?.golden_gate_reaction_size || '20',
                            traditional_protocol: preferences.cloning_preferences?.traditional_protocol || 't4_dna_ligation_neb',
                            traditional_reaction_size: preferences.cloning_preferences?.traditional_reaction_size || '20'
                        }
                    });
                    
                    setSelectedProtocol(preferences.cloning_preferences?.pcr_protocol || 'q5_high_fidelity_neb');
                    setSelectedDigestVendor(preferences.cloning_preferences?.digest_vendor || 'NEB');
                    setSelectedSynthesisProtocol(preferences.cloning_preferences?.synthesis_to_order_protocol || 'synthesis_to_order_idt_gene');
                    setSelectedSynthesisPrimerProtocol(preferences.cloning_preferences?.synthesis_to_order_primer_protocol || 'synthesis_to_order_idt_primer');
                    setSelectedGibsonProtocol(preferences.cloning_preferences?.gibson_protocol || 'nebuilder_hifi_neb');
                    setSelectedGoldenGateProtocol(preferences.cloning_preferences?.golden_gate_protocol || 'nebridge_bsmbiv2_neb');
                    setSelectedTraditionalProtocol(preferences.cloning_preferences?.traditional_protocol || 't4_dna_ligation_neb');
                    setSelectedGibsonRegeneration(preferences.cloning_preferences?.gibson_restriction_site_regeneration || 'None');
                    setGibsonHomologyLength(preferences.cloning_preferences?.gibson_default_homology_length || '40');
                    setPreferencesLoaded(true);
                } catch (err) {
                    console.error("Error fetching user preferences:", err);
                }
            }
        };

        fetchUserPreferences();
    }, [preferencesLoaded, user, token]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target)
            ) {
                dispatch(updateModal({ name: '', data: null }));
                setSuccessMessage("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    const toggleEdit = (field) => {
        setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const handleInputChange = (field, value) => {
        setUserPrefs((prev) => ({
            ...prev,
            cloning_preferences: {
                ...prev.cloning_preferences,
                [field]: value // Remove String() conversion
            }
        }));
    };

    const handleSave = async () => {
        try {
            const reactionSizes = [
                'pcr_reaction_size',
                'digest_reaction_size',
                'synthesis_to_order_reaction_size',
                'gibson_reaction_size',
                'golden_gate_reaction_size',
                'traditional_reaction_size',
                'primer_concentration'
            ];

            for (let sizeField of reactionSizes) {
                let size = userPrefs.cloning_preferences?.[sizeField];
                if (size !== "" && size !== undefined) {
                    size = parseFloat(size);
                    if (isNaN(size) || size <= 0) {
                        setErrorMessage(`${sizeField.replace(/_/g, ' ').charAt(0).toUpperCase() + sizeField.replace(/_/g, ' ').slice(1)} must be a positive number or left empty.`);
                        return;
                    }
                }
            }

            const updatedPrefs = {
                ...userPrefs,
                user_id: String(user.user_id),
                cloning_preferences: {
                    ...userPrefs.cloning_preferences,
                    pcr_protocol: selectedProtocol ? String(selectedProtocol) : "",
                    digest_vendor: selectedDigestVendor ? String(selectedDigestVendor) : "",
                    synthesis_to_order_protocol: selectedSynthesisProtocol ? String(selectedSynthesisProtocol) : "",
                    synthesis_to_order_primer_protocol: selectedSynthesisPrimerProtocol ? String(selectedSynthesisPrimerProtocol) : "",
                    gibson_protocol: selectedGibsonProtocol ? String(selectedGibsonProtocol) : "",
                    golden_gate_protocol: selectedGoldenGateProtocol ? String(selectedGoldenGateProtocol) : "",
                    traditional_protocol: selectedTraditionalProtocol ? String(selectedTraditionalProtocol) : "",
                    pcr_reaction_size: userPrefs.cloning_preferences?.pcr_reaction_size,
                    digest_reaction_size: userPrefs.cloning_preferences?.digest_reaction_size,
                    synthesis_to_order_reaction_size: userPrefs.cloning_preferences?.synthesis_to_order_reaction_size,
                    gibson_reaction_size: userPrefs.cloning_preferences?.gibson_reaction_size,
                    traditional_reaction_size: userPrefs.cloning_preferences?.traditional_reaction_size,
                    primer_concentration: userPrefs.cloning_preferences?.primer_concentration,
                }
            };
            const savedPrefs = await saveUserPreferences(updatedPrefs, token);
            setUserPrefs(savedPrefs);
            setEditMode({});
            setErrorMessage("");
            setSuccessMessage("Preferences saved successfully!");
        } catch (error) {
            console.error("Failed to save user preferences:", error);
            setErrorMessage("Failed to save preferences. Please try again.");
        }
    };

    const handleProtocolChange = (e, type) => {
        const value = e.target.value;
        switch (type) {
            case 'pcr':
                setSelectedProtocol(value);
                handleInputChange("pcr_protocol", value);
                break;
            case 'digest_vendor':
                setSelectedDigestVendor(value);
                handleInputChange("digest_vendor", value);
                break;
            case 'synthesis_to_order':
                setSelectedSynthesisProtocol(value);
                handleInputChange("synthesis_to_order_protocol", value);
                break;
            case 'synthesis_to_order_primer':
                setSelectedSynthesisPrimerProtocol(value);
                handleInputChange("synthesis_to_order_primer_protocol", value);
                break;
            case 'gibson':
                setSelectedGibsonProtocol(value);
                handleInputChange("gibson_protocol", value);
                break;
            case 'golden_gate':
                setSelectedGoldenGateProtocol(value);
                handleInputChange("golden_gate_protocol", value);
                break;
            case 'traditional':
                setSelectedTraditionalProtocol(value);
                handleInputChange("traditional_protocol", value);
                break;
            case 'gibson_regeneration':
                setSelectedGibsonRegeneration(value);
                handleInputChange("gibson_restriction_site_regeneration", value);
                break;
            case 'gibson_homology':
                setGibsonHomologyLength(value);
                handleInputChange("gibson_default_homology_length", value);
                break;
        }
    };

    const renderProtocolDropdown = (type, value, list) => {
        return (
            <select
                value={value}
                onChange={(e) => handleProtocolChange(e, type)}
                className="up-dropdown"
            >
                <option value="">Select a {type.replace('_', ' ')} Protocol</option>
                {Object.entries(list).map(([key, { Name }]) => (
                    <option key={key} value={key}>{Name}</option>
                ))}
            </select>
        );
    };

    const renderDigestVendorDropdown = () => {
        return (
            <select
                value={selectedDigestVendor}
                onChange={(e) => handleProtocolChange(e, 'digest_vendor')}
                className="up-dropdown"
            >
                <option value="NEB">NEB</option>
                <option value="Thermo">Thermo</option>
            </select>
        );
    };

    const dismissMessage = () => {
        setSuccessMessage("");
        setErrorMessage("");
    };

    if (!user) {
        return (
            <div className="up-modal-overlay">
                <div className="up-modal-content" ref={modalRef}>
                    <h2>Loading user data...</h2>
                </div>
            </div>
        );
    }

    return (
        <div className="up-modal-overlay">
            <div className="up-modal-content" ref={modalRef}>
                <h2>User Preferences</h2>
                {errorMessage && (
                    <div className="up-error-message">
                        <p>{errorMessage}</p>
                        <button onClick={dismissMessage} className="up-dismiss-button">×</button>
                    </div>
                )}
                {successMessage && (
                    <div className="up-success-message">
                        <p>{successMessage}</p>
                        <button onClick={dismissMessage} className="up-dismiss-button">×</button>
                    </div>
                )}
                <div className="up-fields-container">
                    <div className="up-field-column">
                        {userPrefs && (
                            <>
                                <div className="up-category">
                                    <h3>PCR</h3>
                                    <div className="up-field">
                                        <label>Protocol:</label>
                                        {renderProtocolDropdown('pcr', selectedProtocol, pcr_protocol_list)}
                                    </div>
                                    <div className="up-field">
                                        <label>Reaction Size:</label>
                                        <input
                                            type="text"
                                            value={userPrefs.cloning_preferences?.pcr_reaction_size || ""}
                                            onChange={(e) => handleInputChange("pcr_reaction_size", e.target.value)}
                                            className="up-input"
                                        />
                                    </div>
                                    <div className="up-field">
                                        <label>Primer Concentration (µM):</label>
                                        <input
                                            type="text"
                                            value={userPrefs.cloning_preferences?.primer_concentration || ""}
                                            onChange={(e) => handleInputChange("primer_concentration", e.target.value)}
                                            className="up-input"
                                        />
                                    </div>
                                </div>

                                <div className="up-category">
                                    <h3>Digest</h3>
                                    <div className="up-field">
                                        <label>Vendor:</label>
                                        {renderDigestVendorDropdown()}
                                    </div>
                                    <div className="up-field">
                                        <label>Reaction Size:</label>
                                        <input
                                            type="text"
                                            value={userPrefs.cloning_preferences?.digest_reaction_size || ""}
                                            onChange={(e) => handleInputChange("digest_reaction_size", e.target.value)}
                                            className="up-input"
                                        />
                                    </div>
                                </div>

                                <div className="up-category">
                                    <h3>Synthesis</h3>
                                    <div className="up-field">
                                        <label>Gene Vendor:</label>
                                        {renderProtocolDropdown('synthesis_to_order', selectedSynthesisProtocol, synthesis_to_order_gene_protocol_list)}
                                    </div>
                                    <div className="up-field">
                                        <label>Primer Vendor:</label>
                                        {renderProtocolDropdown('synthesis_to_order_primer', selectedSynthesisPrimerProtocol, synthesis_to_order_primer_protocol_list)}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="up-field-column">
                        {userPrefs && (
                            <>
                                <div className="up-category">
                                    <h3>Gibson</h3>
                                    <div className="up-field">
                                        <label>Protocol:</label>
                                        {renderProtocolDropdown('gibson', selectedGibsonProtocol, gibson_protocol_list)}
                                    </div>
                                    <div className="up-field">
                                        <label>Reaction Size:</label>
                                        <input
                                            type="text"
                                            value={userPrefs.cloning_preferences?.gibson_reaction_size || ""}
                                            onChange={(e) => handleInputChange("gibson_reaction_size", e.target.value)}
                                            className="up-input"
                                        />
                                    </div>
                                    <div className="up-field">
                                        <label>Restriction Site Regeneration:</label>
                                        <select
                                            value={selectedGibsonRegeneration}
                                            onChange={(e) => handleProtocolChange(e, 'gibson_regeneration')}
                                            className="up-dropdown"
                                        >
                                            <option value="None">None</option>
                                            <option value="Strict">Strict</option>
                                            <option value="Eliminate">Eliminate</option>
                                        </select>
                                    </div>
                                    <div className="up-field">
                                        <label>Homology Length:</label>
                                        <input
                                            type="number"
                                            value={gibsonHomologyLength}
                                            onChange={(e) => handleProtocolChange(e, 'gibson_homology')}
                                            className="up-input"
                                            min="1"
                                        />
                                    </div>
                                </div>

                                <div className="up-category">
                                    <h3>Golden Gate</h3>
                                    <div className="up-field">
                                        <label>Protocol:</label>
                                        {renderProtocolDropdown('golden_gate', selectedGoldenGateProtocol, golden_gate_protocol_list)}
                                    </div>
                                    <div className="up-field">
                                        <label>Reaction Size:</label>
                                        <input
                                            type="text"
                                            value={userPrefs.cloning_preferences?.golden_gate_reaction_size || ""}
                                            onChange={(e) => handleInputChange("golden_gate_reaction_size", e.target.value)}
                                            className="up-input"
                                        />
                                    </div>
                                </div>

                                <div className="up-category">
                                    <h3>Traditional</h3>
                                    <div className="up-field">
                                        <label>Protocol:</label>
                                        {renderProtocolDropdown('traditional', selectedTraditionalProtocol, traditional_protocol_list)}
                                    </div>
                                    <div className="up-field">
                                        <label>Reaction Size:</label>
                                        <input
                                            type="text"
                                            value={userPrefs.cloning_preferences?.traditional_reaction_size || ""}
                                            onChange={(e) => handleInputChange("traditional_reaction_size", e.target.value)}
                                            className="up-input"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="up-modal-footer">
                    <button onClick={handleSave} className="up-save-button">
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
}

export default UserPreferencesModal;
