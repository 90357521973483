import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { handleLogin, deleteFilePermanent, getCredential, addSampleProject, getUserInfo, fetchUsageCredits, fetchUsageGroups, addGroupMember, deleteGroup, removeGroupMember, changeGroupOwner, transferCreditsToGroup, transferCreditsFromGroup, renameGroup, leaveGroup } from "../api.js";
import axios from 'axios';
import { fetchProjects } from './project.js'; // Add this import at the top of the file

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true, // This is crucial for sending/receiving cookies
});

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async ({ token, user_auth0 }, { dispatch, rejectWithValue }) => {

    const response = await handleLogin({ token, user_auth0 });

    // if (response && response.data && response.data.user_id) {
    //   dispatch(fetchProjects(response.data.user_id));
    // }
    return response;

  }
);

export const deleteFileFromLibrary = createAsyncThunk(
  "user/deleteFileFromLibrary",
  async ({ fileIds, fileNames, userId }, { getState }) => {
    const token = getState().user.access_token;
    console.log("files:", getState().user.files);
    const results = await deleteFilePermanent(fileIds, fileNames, userId, token);
    return results;
  }
);

export const fetchCredential = createAsyncThunk(
  "user/fetchCredential",
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCredential();
      console.log("Credential fetched:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching credential:", error);
      return rejectWithValue(error.message);
    }
  }
);

export const populateSampleData = createAsyncThunk(
  "user/populateSampleData",
  async ({ userId, sampleNumber }, { getState, dispatch }) => {
    const token = getState().user.access_token;
    console.log("populateSampleData userId", userId);
    await addSampleProject(userId, sampleNumber, token);
    const user = await getUserInfo(userId, token);
    console.log("user after populateSampleData", user);

    // Dispatch fetchProjects to update the project list
    dispatch(fetchProjects(userId));

    return user;
  }
);

export const fetchGroups = createAsyncThunk(
  "user/fetchGroups",
  async (_, { getState }) => {
    const state = getState();
    const userId = state.user.user_id;
    const token = state.user.access_token;
    try {
      const groups = await fetchUsageGroups(userId, token);
      console.log("fetchGroups groups", groups);
      return groups;
    } catch (error) {
      console.error('Error fetching groups:', error);
      throw error;
    }
  }
);

export const addMemberToGroup = createAsyncThunk(
  "user/addMemberToGroup",
  async ({ groupId, email }, { getState }) => {
    const state = getState();
    const token = state.user.access_token;
    const ownerId = state.user.user_id;
    const updatedGroups = await addGroupMember(groupId, email, ownerId, token);
    console.log('addMemberToGroup result:', updatedGroups);
    return updatedGroups;
  }
);

export const deleteGroupThunk = createAsyncThunk(
  "user/deleteGroup",
  async (groupId, { getState }) => {
    const state = getState();
    const userId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await deleteGroup(groupId, userId, token);
    console.log('deleteGroupThunk result:', updatedGroups);
    return updatedGroups;
  }
);

export const removeGroupMemberThunk = createAsyncThunk(
  "user/removeGroupMember",
  async ({ groupId, userEmail }, { getState }) => {
    const state = getState();
    const ownerId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await removeGroupMember(groupId, ownerId, userEmail, token);
    console.log('removeGroupMemberThunk result:', updatedGroups);
    return updatedGroups; // Return the entire groups object
  }
);

export const changeGroupOwnerThunk = createAsyncThunk(
  "user/changeGroupOwner",
  async ({ groupId, newOwnerEmail }, { getState }) => {
    const state = getState();
    const ownerId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await changeGroupOwner(groupId, ownerId, newOwnerEmail, token);
    console.log('changeGroupOwnerThunk result:', updatedGroups);
    return updatedGroups;
  }
);

export const transferCreditsToGroupThunk = createAsyncThunk(
  "user/transferCreditsToGroup",
  async ({ groupId, amount }, { getState }) => {
    const state = getState();
    const ownerId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await transferCreditsToGroup(groupId, ownerId, amount, token);
    console.log('transferCreditsToGroupThunk result:', updatedGroups);
    return updatedGroups;
  }
);

export const transferCreditsFromGroupThunk = createAsyncThunk(
  "user/transferCreditsFromGroup",
  async ({ groupId, amount }, { getState }) => {
    const state = getState();
    const ownerId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await transferCreditsFromGroup(groupId, ownerId, amount, token);
    console.log('transferCreditsFromGroupThunk result:', updatedGroups);
    return updatedGroups;
  }
);

export const renameGroupThunk = createAsyncThunk(
  "user/renameGroup",
  async ({ groupId, newName }, { getState }) => {
    const state = getState();
    const ownerId = state.user.user_id;
    const token = state.user.access_token;
    const updatedGroups = await renameGroup(groupId, ownerId, newName, token);
    console.log('renameGroupThunk result:', updatedGroups);
    return updatedGroups;
  }
);

export const leaveGroupThunk = createAsyncThunk(
  "user/leaveGroup",
  async ({ groupId, userEmail }, { getState }) => {
    const state = getState();
    const token = state.user.access_token;
    const updatedGroups = await leaveGroup(groupId, userEmail, token);
    console.log('leaveGroupThunk result:', updatedGroups);
    return updatedGroups;
  }
);

const initialState = {
  data: null,
  status: "idle",
  error: null,
  credential: "init",
  login_provider: null,
  access_token: null,
  user_id: null,
  api_url: API_URL, // Add api_url to the initial state
  files: {},
  popupMessage: null, // Add popupMessage to the initial state
  usageCredits: 0, // Add this line
  groups: [],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser(state, action) {
      console.log("UPDATE USER CALLED", action.payload);
      state.data = action.payload;
      if (action.payload.user_id) {
        state.user_id = action.payload.user_id;
      }
      if (action.payload.files) {
        state.files = action.payload.files;
        state.data.dna_files = Object.fromEntries(
          Object.entries(state.files).filter(([key, file]) => file.file_type === "DNA" || file.file_type === "TargetConstruct")
        );
      }
    },
    updateUserFiles(state, action) {
      const { fileId, newName } = action.payload;
      console.log("Updating user files: pending");
      if (state.data.files[fileId]) {
        console.log("State data files", state.data.files);
        state.data.files[fileId].file_name = newName;
        console.log("Updating user files: succeeded", state.data.files[fileId]);
      } else {
        console.log("Updating user files: failed, file not found", fileId);
      }
    },
    setCredential(state, action) {
      state.credential = action.payload.credential;
      state.login_provider = action.payload.login_provider;
      state.access_token = action.payload.access_token;
    },
    updateAccessToken(state, action) {
      state.access_token = action.payload;
    },
    updateUserFile(state, action) {
      const { fileId, updatedFile } = action.payload;
      if (state.data && state.data.files) {
        state.data.files[fileId] = updatedFile;

        // Update dna_files if it exists and the file is a DNA file
        if (state.data.dna_files && updatedFile.file_type === "DNA") {
          state.data.dna_files[fileId] = updatedFile;
        }
      }
      // Also update the files in the root state
      state.files[fileId] = updatedFile;
    },
    setPopupMessage(state, action) {
      state.popupMessage = action.payload;
    },
    setUsageCredits(state, action) {
      state.usageCredits = action.payload;
    },
    setGroups(state, action) {
      state.groups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log("fetchUser.fulfilled", action.payload);
        state.data = action.payload.data.data;
        if (action.payload.data?.data?.user_id) {
          state.user_id = action.payload.data.data.user_id;
        }

        if (action.payload.access_token) {
          state.access_token = action.payload.access_token;
        }
        if (action.payload.data?.data?.files) {
          state.files = action.payload.data.data.files || { '0': { file_id: '0', file_name: 'test' } };
          console.log("state.files", state.files);
          state.data.dna_files = Object.fromEntries(
            Object.entries(state.files).filter(([key, file]) => file.file_type === "DNA" || file.file_type === "TargetConstruct")
          );
        }
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
        state.data = null;
        state.user_id = null;
        state.access_token = null;
      })
      .addCase(deleteFileFromLibrary.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteFileFromLibrary.fulfilled, (state, action) => {
        state.status = "succeeded";
        action.payload.forEach(result => {
          if (result.success) {
            delete state.data.files[result.fileId];
            delete state.files[result.fileId];
            if (state.data.dna_files) {
              delete state.data.dna_files[result.fileId];
            }
          }
        });
      })
      .addCase(fetchCredential.pending, (state) => {
        console.log("Fetching credential: pending");
      })
      .addCase(fetchCredential.fulfilled, (state, action) => {
        console.log("Fetching credential: fulfilled", action.payload);
        state.credential = action.payload.credential;
        state.login_provider = action.payload.login_provider;
        state.access_token = action.payload.access_token; // Ensure this is correctly obtained from the response
      })
      .addCase(fetchCredential.rejected, (state, action) => {
        console.log("Fetching credential: rejected", action.payload);
        state.error = action.payload;
      })
      .addCase(populateSampleData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        if (action.payload.files) {
          state.files = action.payload.files;
          state.data.dna_files = Object.fromEntries(
            Object.entries(action.payload.files).filter(([key, file]) => file.file_type === "DNA")
          );
        }
        console.log(`Sample data populated`);
      })
      .addCase(populateSampleData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchGroups.fulfilled, (state, action) => {
        state.groups = action.payload;
      })
      .addCase(addMemberToGroup.fulfilled, (state, action) => {
        state.groups = action.payload;
      })
      .addCase(deleteGroupThunk.fulfilled, (state, action) => {
        state.groups = action.payload;
      })
      .addCase(removeGroupMemberThunk.fulfilled, (state, action) => {
        // Update the entire groups object
        state.groups = action.payload;
      })
      .addCase(changeGroupOwnerThunk.fulfilled, (state, action) => {
        state.groups = action.payload;
      })
      .addCase(transferCreditsToGroupThunk.fulfilled, (state, action) => {
        state.groups = action.payload;
      })
      .addCase(transferCreditsFromGroupThunk.fulfilled, (state, action) => {
        state.groups = action.payload;
      })
      .addCase(renameGroupThunk.fulfilled, (state, action) => {
        state.groups = action.payload;
      })
      .addCase(leaveGroupThunk.fulfilled, (state, action) => {
        state.groups = action.payload;
      });
  },
});

export const { 
  updateUser, 
  updateUserFiles, 
  updateUserFile, 
  setCredential, 
  updateAccessToken,
  setPopupMessage, // Add this export
  setUsageCredits, // Add this export
  setGroups,
} = userSlice.actions;

// New thunk to fetch usage credits
export const fetchUserCredits = createAsyncThunk(
  "user/fetchUserCredits",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const userId = state.user.user_id;
    const token = state.user.access_token;
    try {
      const credits = await fetchUsageCredits(userId, token);
      dispatch(setUsageCredits(credits));
      return credits;
    } catch (error) {
      console.error('Error fetching user credits:', error);
      throw error;
    }
  }
);

export default userSlice.reducer;