import React, { useState } from "react";
import "../style/ManageGroup.css";
import { useSelector, useDispatch } from "react-redux";
import { addMemberToGroup } from "../store/user.js";
import { deleteGroupThunk, removeGroupMemberThunk, changeGroupOwnerThunk, transferCreditsToGroupThunk, transferCreditsFromGroupThunk, renameGroupThunk, leaveGroupThunk } from "../store/user.js";

const ManageGroup = ({ group, onClose }) => {
    const [showRename, setShowRename] = useState(false);
    const [newName, setNewName] = useState(group.name);
    const [showAddMember, setShowAddMember] = useState(false);
    const [newEmail, setNewEmail] = useState("");
    const [creditsToAdd, setCreditsToAdd] = useState("");
    const [newOwner, setNewOwner] = useState("");
    const currentUserId = useSelector(state => state.user.user_id);
    const isOwner = group.owner === currentUserId;
    const [creditsToModify, setCreditsToModify] = useState("");
    const [showAddCredits, setShowAddCredits] = useState(false);
    const [showRemoveCredits, setShowRemoveCredits] = useState(false);
    const [showAddMemberForm, setShowAddMemberForm] = useState(false);
    const [showRemoveMemberForm, setShowRemoveMemberForm] = useState(false);
    const [showChangeOwner, setShowChangeOwner] = useState(false);
    const [activeAction, setActiveAction] = useState(null);
    const totalUsageCredits = useSelector(state => state.user.usageCredits) || 0;
    const groupsObj = useSelector(state => state.user.groups || {});
    const [errorMessage, setErrorMessage] = useState("");

    // Calculate available credits by subtracting all group credits from total
    const groupCreditsSum = Object.values(groupsObj).reduce((sum, group) => sum + (group.num_credits || 0), 0);
    const availableCredits = totalUsageCredits - groupCreditsSum;

    const dispatch = useDispatch();

    const handleRename = async (e) => {
        e.preventDefault();
        try {
            await dispatch(renameGroupThunk({ 
                groupId: group.group_id, 
                newName 
            })).unwrap();
            setShowRename(false);
        } catch (error) {
            console.error('Failed to rename group:', error);
        }
    };

    const handleDeleteGroup = async () => {
        if (window.confirm("Are you sure you want to delete this group?")) {
            try {
                await dispatch(deleteGroupThunk(group.group_id)).unwrap();
                onClose();
            } catch (error) {
                console.error('Failed to delete group:', error);
            }
        }
    };

    const handleAddMember = async (e) => {
        e.preventDefault();
        if (newEmail) {
            try {
                await dispatch(addMemberToGroup({ 
                    groupId: group.group_id, 
                    email: newEmail 
                })).unwrap();
                setNewEmail("");
                setShowAddMemberForm(false);
                setErrorMessage("");
            } catch (error) {
                console.error('Failed to add member:', error);
                setErrorMessage(error.message || "Failed to add member to group. Please ensure they have already signed up with the same email.");
            }
        }
    };

    const handleRemoveMember = async (email) => {
        if (window.confirm(`Remove ${email} from the group?`)) {
            try {
                await dispatch(removeGroupMemberThunk({
                    groupId: group.group_id,
                    userEmail: email
                })).unwrap();
            } catch (error) {
                console.error('Failed to remove member:', error);
            }
        }
    };

    const handleLeaveGroup = async () => {
        if (window.confirm("Are you sure you want to leave this group?")) {
            try {
                const userEmail = Object.entries(group.member_ids)
                    .find(([id]) => id === currentUserId.toString())?.[1];
                    
                if (userEmail) {
                    await dispatch(leaveGroupThunk({
                        groupId: group.group_id,
                        userEmail: userEmail
                    })).unwrap();
                    onClose();
                }
            } catch (error) {
                console.error('Failed to leave group:', error);
            }
        }
    };

    const handleChangeOwner = async (e) => {
        e.preventDefault();
        try {
            await dispatch(changeGroupOwnerThunk({
                groupId: group.group_id,
                newOwnerEmail: newOwner
            })).unwrap();
            setNewOwner("");
            handleActionClick('owner');
        } catch (error) {
            console.error('Failed to change owner:', error);
        }
    };

    const handleAddCredits = async (e) => {
        e.preventDefault();
        if (creditsToModify && parseInt(creditsToModify) > 0) {
            try {
                await dispatch(transferCreditsToGroupThunk({
                    groupId: group.group_id,
                    amount: parseInt(creditsToModify)
                })).unwrap();
                setCreditsToModify("");
                setShowAddCredits(false);
            } catch (error) {
                console.error('Failed to add credits:', error);
            }
        }
    };

    const handleRemoveCredits = async (e) => {
        e.preventDefault();
        const credits = parseInt(creditsToModify);
        if (credits > 0 && credits <= group.num_credits) {
            try {
                await dispatch(transferCreditsFromGroupThunk({
                    groupId: group.group_id,
                    amount: credits
                })).unwrap();
                setCreditsToModify("");
                setShowRemoveCredits(false);
            } catch (error) {
                console.error('Failed to remove credits:', error);
            }
        }
    };

    const isOwnerInGroup = Object.keys(group.member_ids).includes(group.owner);

    const handleActionClick = (action) => {
        if (activeAction === action) {
            setActiveAction(null);
            if (action === 'rename') setNewName(group.name);
            if (action === 'owner') setNewOwner('');
        } else {
            setActiveAction(action);
            setNewName(group.name);
            setNewOwner('');
        }
    };

    const handleBack = () => {
        onClose();
    };

    return (
        <div className="manage-modal-overlay" onClick={onClose}>
            <div className="manage-modal-content" onClick={e => e.stopPropagation()}>
                <div className="manage-modal-header">
                    <h2 className="manage-modal-title">
                        {showRename ? "Rename Group" : `${group.name}`}
                    </h2>
                    <button className="manage-back-button" onClick={handleBack}>
                        ← Back to Groups
                    </button>
                </div>
                <hr className="manage-modal-divider" />

                <div className="manage-section">
                    <div className="manage-header">
                        <h3>Members ({group.member_count})</h3>
                        {isOwner && (
                            <button
                                className="action-button"
                                onClick={() => setShowAddMemberForm(true)}
                            >
                                Add Member
                            </button>
                        )}
                    </div>

                    <div className="member-list">
                        {Object.entries(group.member_ids).map(([userId, email]) => (
                            <div key={userId} className="member-item">
                                <span>{email} {userId === group.owner && "(owner)"}</span>
                                {isOwner && userId !== currentUserId && (
                                    <img
                                        src="/images/trash.svg"
                                        alt="Remove member"
                                        className="remove-icon"
                                        onClick={() => handleRemoveMember(email)}
                                    />
                                )}
                                {!isOwner && userId === currentUserId && (
                                    <button
                                        className="leave-button"
                                        onClick={handleLeaveGroup}
                                    >
                                        Leave Group
                                    </button>
                                )}
                            </div>
                        ))}
                    </div>

                    {showAddMemberForm && (
                        <form onSubmit={handleAddMember} className="manage-form">
                            <input
                                type="email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                                placeholder="Enter member email"
                                autoFocus
                            />
                            {errorMessage && (
                                <div className="manage-group-error-message">
                                    {errorMessage}
                                </div>
                            )}
                            <div className="form-buttons">
                                <button type="submit">Add</button>
                                <button type="button" onClick={() => {
                                    setShowAddMemberForm(false);
                                    setErrorMessage("");
                                }}>Cancel</button>
                            </div>
                        </form>
                    )}
                </div>

                {isOwner && (
                    <div className="manage-section">
                        <div className="manage-header">
                            <h3>Credits ({group.num_credits})</h3>
                            <div className="manage-buttons">
                                <button 
                                    className="action-button"
                                    onClick={() => {
                                        setShowAddCredits(true);
                                        setShowRemoveCredits(false);
                                    }}
                                >
                                    Add Credits
                                </button>
                                <button 
                                    className="action-button"
                                    onClick={() => {
                                        setShowRemoveCredits(true);
                                        setShowAddCredits(false);
                                    }}
                                >
                                    Remove Credits
                                </button>
                            </div>
                        </div>

                        {(showAddCredits || showRemoveCredits) && (
                            <form 
                                onSubmit={showAddCredits ? handleAddCredits : handleRemoveCredits} 
                                className="manage-form"
                            >
                                <div className="credits-input-container">
                                    <input
                                        type="number"
                                        min="1"
                                        max={showRemoveCredits ? group.num_credits : availableCredits}
                                        value={creditsToModify}
                                        onChange={(e) => setCreditsToModify(e.target.value)}
                                        placeholder={`Credits to ${showAddCredits ? 'add' : 'remove'}`}
                                    />
                                    {showAddCredits && (
                                        <span className="credits-available">
                                            {availableCredits} credits available to add
                                        </span>
                                    )}
                                </div>
                                <div className="form-buttons">
                                    <button type="submit">
                                        {showAddCredits ? 'Add' : 'Remove'}
                                    </button>
                                    <button 
                                        type="button" 
                                        onClick={() => {
                                            setShowAddCredits(false);
                                            setShowRemoveCredits(false);
                                            setCreditsToModify("");
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                )}

                {isOwner && (
                    <div className="manage-section">
                        <div className="manage-header">
                            <h3>Group Actions</h3>
                        </div>
                        <div className="group-actions">
                            <button
                                className={`action-button ${activeAction === 'rename' ? 'active' : ''}`}
                                onClick={() => handleActionClick('rename')}
                            >
                                Rename Group
                            </button>
                            {isOwnerInGroup && (
                                <button
                                    className={`action-button ${activeAction === 'owner' ? 'active' : ''}`}
                                    onClick={() => handleActionClick('owner')}
                                >
                                    Change Owner
                                </button>
                            )}
                            <button
                                className="delete-button"
                                onClick={handleDeleteGroup}
                            >
                                Delete Group
                            </button>
                        </div>

                        {activeAction === 'rename' && (
                            <form onSubmit={handleRename} className="manage-form">
                                <input
                                    type="text"
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                    placeholder="New group name"
                                    autoFocus
                                />
                                <div className="form-buttons">
                                    <button type="submit">Save</button>
                                    <button type="button" onClick={() => handleActionClick('rename')}>Cancel</button>
                                </div>
                            </form>
                        )}

                        {activeAction === 'owner' && (
                            <form onSubmit={handleChangeOwner} className="manage-form">
                                <input
                                    type="email"
                                    value={newOwner}
                                    onChange={(e) => setNewOwner(e.target.value)}
                                    placeholder="New owner email"
                                    autoFocus
                                />
                                <div className="form-buttons">
                                    <button
                                        type="submit"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (window.confirm(`Are you sure you want to transfer ownership to ${newOwner}? This action cannot be undone.`)) {
                                                handleChangeOwner(e);
                                                handleActionClick('owner');
                                            }
                                        }}
                                    >
                                        Transfer
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleActionClick('owner')}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ManageGroup;
