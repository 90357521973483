import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateArtifact, setCurrentStreamedUpdate } from "../store/project.js";
import DOMPurify from 'dompurify';
import "../style/ChatMessages.css";

function ChatMessages() {
    const dispatch = useDispatch();
    const { chat, currentProjectId, isLoading, currentStreamedUpdate, isChatLoading } = useSelector((state) => state.project);
    const [messages, setMessages] = useState(chat[currentProjectId]);
    const user_id = useSelector((state) => state.user.user_id);
    const token = useSelector(state => state.user.access_token);

    const scroll = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" }); // Changed to "smooth"
            setIsScrolled(true);
        }
    };

    useEffect(() => {
        setMessages(chat[currentProjectId]);
    }, [chat, currentProjectId, user_id]);

    useEffect(() => {
        setIsScrolled(false);
        scroll();
    }, [messages]); // Add currentProjectId to dependencies

    const messagesEndRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            const chatList = messagesEndRef.current.parentNode?.parentNode;
            if (chatList) {
                chatList.classList.add("hide-scrollbar");
                messagesEndRef.current.scrollIntoView({ behavior: "auto" });
                setIsScrolled(true);
                setTimeout(() => {
                    chatList.classList.remove("hide-scrollbar");
                }, 500); // Adjust the timeout duration as needed
            }
        }
    };

    useEffect(() => {
        setIsScrolled(false);
        scrollToBottom();
    }, [chat]);

    const renderMessage = (message) => {
        // Sanitize the message
        const sanitizedMessage = DOMPurify.sanitize(message);

        // Parse the sanitized message as HTML
        const parser = new DOMParser();
        const doc = parser.parseFromString(sanitizedMessage, "text/html");

        return Array.from(doc.body.childNodes).map((node, index) => {
            if (node.nodeType === Node.TEXT_NODE) {
                // Handle text nodes
                const parts = node.textContent.split(/(\[artifact:.*?\]|\*\*.*?\*\*|https?:\/\/\S+|www\.\S+|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b)/);
                return parts.map((part, partIndex) => {
                    if (part.startsWith("[artifact:") && part.endsWith("]")) {
                        const artifactData = JSON.parse(part.slice(9, -1));
                        return (
                            <span
                                key={`${index}-${partIndex}`}
                                className="artifact-link"
                                onClick={() => {
                                    dispatch(updateArtifact(artifactData));
                                }}
                            >
                                [View Artifact]
                            </span>
                        );
                    } else if (part.startsWith("**") && part.endsWith("**")) {
                        return (
                            <strong key={`${index}-${partIndex}`}>
                                {part.slice(2, -2)}
                            </strong>
                        );
                    } else if (part.match(/^(https?:\/\/\S+|www\.\S+)\.?$/)) {
                        const linkText = part.endsWith('.') ? part.slice(0, -1) : part;
                        const href = linkText.startsWith('www.') ? `http://${linkText}` : linkText;
                        return (
                            <>
                                <a
                                    key={`${index}-${partIndex}`}
                                    href={href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {linkText}
                                </a>
                                {part.endsWith('.') ? '.' : ''}
                            </>
                        );
                    } else if (part.match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/)) {
                        return (
                            <a
                                key={`${index}-${partIndex}`}
                                href={`mailto:${part}`}
                            >
                                {part}
                            </a>
                        );
                    }
                    return part;
                });
            } else if (
                node.nodeType === Node.ELEMENT_NODE &&
                node.classList.contains("file-tag")
            ) {
                // Handle file-tag spans, preserving the data-file-id attribute
                return (
                    <span
                        key={index}
                        className="file-tag"
                        data-file-id={node.getAttribute("data-file-id")}
                    >
                        {node.textContent}
                    </span>
                );
            }
            // For other elements, return them as-is
            return node.outerHTML;
        });
    };

    const renderLoadingAnimation = () => {
        if (!messages || isChatLoading) {
            return (
                <li className="message ai-message">
                    <img src="/images/K-mark.svg" alt="LabKick" className="ai-logo" />
                    <div className="message-content">
                        <div className="loading-content">
                            <div className="loading-animation">
                                <span></span><span></span><span></span>
                            </div>
                            {currentStreamedUpdate && <p>{renderMessage(currentStreamedUpdate)}</p>}
                        </div>
                    </div>
                </li>
            );
        }
        return null;
    };

    useEffect(() => {
        if (!isChatLoading && currentStreamedUpdate) {
            dispatch(setCurrentStreamedUpdate(""));
        }
    }, [isChatLoading, currentStreamedUpdate, dispatch]);

    useEffect(() => {
        if (isChatLoading) {
            scrollToBottom();
        }
    }, [isChatLoading]);

    return (
        <div className="chat-messages-container">
            <div className={`chat-messages-list ${isScrolled ? "visible" : "hidden"}`}>
                {currentProjectId ? (
                    <ul className="chat-messages-list hide-scrollbar">
                        {messages?.chat_contents?.map((message, idx) => (
                            <li
                                key={idx}
                                className={`message ${message.sender === "user" ? "user-message" : "ai-message"}`}
                            >
                                {message.sender !== "user" && (
                                    <img src="/images/K-mark.svg" alt="LabKick" className="ai-logo" />
                                )}
                                <div className="message-content">
                                    <p>{renderMessage(message.message)}</p>
                                </div>
                            </li>
                        ))}
                        {renderLoadingAnimation()}
                        <div ref={messagesEndRef} />
                    </ul>
                ) : (
                    <p>Please select a project to view messages.</p>
                )}
            </div>
        </div>
    );
}

export default ChatMessages;